import { useMemo } from 'react';
import Select from 'react-select';

import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { RMText } from '../RMText/RMText';

import {
  SearchSelectError,
  selectContainerClassName,
  selectControlClassName,
  selectMenuClassName,
  selectMenuListClassName,
  StyledSearchSelect,
} from './RMSearchSelect.styles';

export interface RMSearchSelectOption {
  label: string;
  value: string;
}

export interface RMSearchSelectProps {
  label?: string;
  options: RMSearchSelectOption[];
  value?: string | null;
  error?: string;
  onChange?: (value: string | null) => void;
}

export function RMSearchSelect({ label, options, value, error, onChange }: RMSearchSelectProps) {
  const isMobile = useIsMobileViewport();

  const selectedValue = useMemo(() => {
    if (value == null) {
      return null;
    }
    return options.find((o) => o.value === value) ?? null;
  }, [options, value]);

  return (
    <StyledSearchSelect>
      {label != null && (
        <RMText type="sans" size="xs" color="on-surface-secondary" bold>
          {label}
        </RMText>
      )}
      <Select<RMSearchSelectOption>
        unstyled
        menuShouldScrollIntoView={isMobile}
        maxMenuHeight={isMobile ? 225 : undefined}
        classNames={{
          container: () => selectContainerClassName,
          control: () => selectControlClassName,
          menu: () => selectMenuClassName,
          option: () => selectMenuListClassName,
        }}
        options={options}
        value={selectedValue}
        onChange={(newValue) => {
          onChange?.(newValue?.value ?? null);
        }}
      />
      {error != null && <SearchSelectError>{error}</SearchSelectError>}
    </StyledSearchSelect>
  );
}
