import { z } from 'zod';

import { Form } from '@/modules/form/@types';
import { DeepPartial } from '@/modules/form/@types-utils';
import { createForm } from '@/modules/form/form';
import { Weekday } from '@/utils/tz';

const TIME_REGEX = /^([01][0-9]|2[0-3]):[0-5][0-9]$/;

export function createPromptSettingsFormSchema() {
  return z
    .object({
      isReceivingPrompts: z.boolean(),
      weekdays: z.set(z.nativeEnum(Weekday)),
      time: z.string().regex(TIME_REGEX, 'This is a required field'),
    })
    .refine(
      (data) => {
        if (!data.isReceivingPrompts) {
          return true;
        }
        return data.weekdays.size > 0;
      },
      {
        message: 'This is a required field',
        path: ['state'],
      },
    );
}

export type PromptSettingsFormInput = z.input<ReturnType<typeof createPromptSettingsFormSchema>>;
export type PromptSettingsForm = Form<PromptSettingsFormInput>;

export function createPromptSettingsForm(defaultValues?: DeepPartial<PromptSettingsFormInput>): PromptSettingsForm {
  return createForm({
    validation: {
      mode: 'onChange',
      display: 'onTouched',
    },
    defaultValues,
    schema: createPromptSettingsFormSchema(),
  });
}
