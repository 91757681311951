import { styled } from '@linaria/react';

export const StyledWeekdaySelector = styled.div`
  display: flex;
  gap: var(--spacing-xs);

  &[data-disabled='true'],
  &[data-soft-disabled='true'] {
    opacity: 0.6;
  }

  &[data-disabled='true'] {
    pointer-events: none;
  }

  body[data-mobile='true'] & {
    gap: 2px;
  }
`;

export const WeekdaySelectorItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 68px;
  border-radius: 2px;
  background-color: hsla(0, 0%, 68%, 1);
  cursor: pointer;

  &[data-selected='true'] {
    background-color: var(--color-spruce-20);
  }

  body[data-mobile='true'] &,
  &[data-size='small'] {
    width: 48px;
    height: 48px;
  }
`;
