import dayjs from 'dayjs';

export enum Weekday {
  SUNDAY = 'Sunday',
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
}

export interface TimezoneInfo {
  id: string;
  offset: number;
  shortAbbreviation: string;
  displayName: string;
}

export function getTimezoneInfo(id: string): TimezoneInfo {
  const now = dayjs().tz(id);
  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone: id,
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  });
  const parts = formatter.formatToParts(now.toDate());
  const timeZonePart = parts.find((part) => part.type === 'timeZoneName');
  const shortAbbreviation = timeZonePart?.value ?? null;

  const offset = now.utcOffset() / 60;
  const offsetHours = Math.floor(Math.abs(offset));
  const offsetMinutes = Math.round((Math.abs(offset) - offsetHours) * 60);
  let displayName = id.replaceAll('_', ' ');
  if (shortAbbreviation != null) {
    displayName += ` (${shortAbbreviation})`;
  }
  displayName += ` - UTC${offset >= 0 ? '+' : '-'}${offsetHours}:${String(offsetMinutes).padStart(2, '0')}`;

  // We should show the id with the offset when the short abbreviation is not available.
  const shortAbbreviationFallback = `${id.replaceAll('_', ' ')} (${offset > 0 ? '+' : '-'}${Math.abs(offset)})`;

  return {
    id,
    offset,
    shortAbbreviation: shortAbbreviation ?? shortAbbreviationFallback,
    displayName,
  };
}

export function getCurrentTimezoneId(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function getCurrentTimezoneInfo(): TimezoneInfo {
  return getTimezoneInfo(getCurrentTimezoneId());
}

export function getAllTimezonesIds(): string[] {
  return Intl.supportedValuesOf('timeZone');
}

export function formatTimeWithTimezone(timeString: string, tz: TimezoneInfo): string {
  const time = dayjs(timeString, 'HH:mm');
  const formattedTime = time.format('h:mm A');
  return `${formattedTime} ${tz.shortAbbreviation}`;
}
