import { UserCoupon, UserCouponType } from '@remento/types/user';
import Cookies from 'js-cookie';

import { getQueryParam } from '@/hooks/useQueryParam';

import { ProjectCheckoutCoupon } from './project-checkout.manager';

export function getProjectCheckoutCoupons(userCoupons: UserCoupon[] | null | undefined): ProjectCheckoutCoupon[] {
  const coupons: ProjectCheckoutCoupon[] = [];

  const userCoupon = userCoupons?.find((c) => c.type === UserCouponType.BUNDLE)?.value;
  if (userCoupon != null) {
    coupons.push({ origin: 'user', coupon: userCoupon });
  }

  const queryParamCoupon = getQueryParam('coupon');
  if (queryParamCoupon != null) {
    coupons.push({ origin: 'query-param', queryParamName: 'coupon', coupon: queryParamCoupon });
  }

  const cookieCoupon = Cookies.get('referral-coupon');
  if (cookieCoupon != null) {
    coupons.push({ origin: 'cookie', cookieName: 'referral-coupon', coupon: cookieCoupon });
  }

  return coupons;
}
