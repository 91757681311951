import { ReactNode, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { UserOnboardingActionType } from '@remento/types/user';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';
import { logger } from '@/logger';
import { useNavbarProjectId, useNavbarStore } from '@/modules/navbar/states';
import { useServices } from '@/Services';
import { CustomOnboardingDialog } from '@/services/local/onboarding-dialog';
import { captureException } from '@/utils/captureException';

import { getProjectSettingsPath } from '../../routing/paths';
import { useNextOnboardingDialog } from '../useNextOnboardingDialog';

// Default onboarding dialog

interface DefaultOnboardingDialogContainerProps {
  id: UserOnboardingActionType;
  title: string;
  content: string | ReactNode;
  actionLabel: string;
}

function DefaultOnboardingDialogContainer({ id, title, content, actionLabel }: DefaultOnboardingDialogContainerProps) {
  // Services
  const { onboardingDialogService } = useServices();
  const navigate = useNavigate();

  // State
  const navbarStore = useNavbarStore();
  const projectId = useNavbarProjectId(navbarStore);

  const handleClose = useCallback(async () => {
    try {
      // This will trigger a refresh in the user, and it will trigger a rerender.
      // The dialog will be closed then.
      await onboardingDialogService.updateOnboardingDialogHistory(id);
    } catch (error) {
      captureException(error, true);
      toast('An unexpected error has occurred.', 'root-toast', 'error');
    }
  }, [id, onboardingDialogService]);

  const handleConfirm = useCallback(async () => {
    try {
      // This will trigger a refresh in the user, and it will trigger a rerender.
      // The dialog will be closed then.
      await onboardingDialogService.updateOnboardingDialogHistory(id);

      switch (id) {
        case UserOnboardingActionType.STORY_PERSPECTIVE_SETTINGS_VIEWED: {
          if (projectId == null) {
            logger.error('PROJECT_ID_NOT_FOUND');
            return;
          }

          navigate(getProjectSettingsPath(projectId, { openPerspectiveSettings: true }));
          break;
        }
        default: {
          break;
        }
      }
    } catch (error) {
      captureException(error, true);
      toast('An unexpected error has occurred.', 'root-toast', 'error');
    }
  }, [id, navigate, onboardingDialogService, projectId]);

  return (
    <RMDialog.Root open onClose={handleClose}>
      <RMDialog.Content>
        <RMDialog.Header title={title} rightAdornment={<RMCloseButton onClick={handleClose} />} />

        <RMDialog.Body>
          <RMText type="sans" size="xs" color="on-surface-primary">
            {content}
          </RMText>
        </RMDialog.Body>

        <RMDialog.Footer>
          <RMButton background="primary" fullWidth autoLoading onClick={handleConfirm}>
            {actionLabel}
          </RMButton>
        </RMDialog.Footer>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}

// Custom onboarding dialog

interface CustomOnboardingDialogContainerProps {
  id: UserOnboardingActionType;
  render: CustomOnboardingDialog['render'];
}

function CustomOnboardingDialogContainer({ id, render }: CustomOnboardingDialogContainerProps) {
  const { onboardingDialogService } = useServices();

  const handleSetOnboardingDone = useCallback(async () => {
    // This will trigger a refresh in the user, and it will trigger a rerender.
    // The dialog will be closed then.
    await onboardingDialogService.updateOnboardingDialogHistory(id);
  }, [id, onboardingDialogService]);

  const portalRoot = document.getElementById('dialog');
  if (!portalRoot) {
    console.error('Missing portal root for dialog');
    return null;
  }

  return createPortal(render({ onSetOnboardingDone: handleSetOnboardingDone }), portalRoot);
}

// Generic container
export function OnboardingDialogContainer() {
  const { onboardingDialogService } = useServices();
  const nextOnboardingDialog = useNextOnboardingDialog();

  // Do not show another onboarding dialog if a dialog has already been shown in the current
  // session.
  if (nextOnboardingDialog == null || onboardingDialogService.isOnboardingDialogAvailable()) {
    return null;
  }

  if (nextOnboardingDialog.dialogType === 'default') {
    return (
      <DefaultOnboardingDialogContainer
        id={nextOnboardingDialog.id}
        title={nextOnboardingDialog.title}
        content={nextOnboardingDialog.content}
        actionLabel={nextOnboardingDialog.actionLabel}
      />
    );
  }

  return <CustomOnboardingDialogContainer id={nextOnboardingDialog.id} render={nextOnboardingDialog.render} />;
}
