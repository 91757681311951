import { ReactNode } from 'react';
import { EmailNotificationSettings } from '@remento/types/user-notification-settings';

import { RMCheckbox } from '@/components/RMCheckbox/RMCheckbox';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { InputController } from '@/modules/form/container';

import { NotificationSettingsEditForm } from '../../forms/notification-settings-edit.form';

import {
  Container,
  NotificationRowDescriptions,
  NotificationsHeader,
  NotificationsRow,
  NotificationsRows,
  NotificationType,
  NotificationTypes,
} from './NotificationSettingsEdit.styles';

export interface NotificationFormItem {
  label: string;
  description: string;
  path: keyof EmailNotificationSettings;
  order: number;
  hidePhone?: boolean;
  extraDescription?: ReactNode;
}

export interface NotificationSettingsEditProps {
  form: NotificationSettingsEditForm;
  notificationFormItems: NotificationFormItem[];
  noPhone: boolean;
}

export function NotificationSettingsEdit({ form, notificationFormItems, noPhone }: NotificationSettingsEditProps) {
  const isMobile = useIsMobileViewport();

  return (
    <Container>
      <NotificationsHeader>
        <RMText type="sans" bold size="xs" color="on-surface-primary">
          NOTIFICATION TYPES
        </RMText>
        <NotificationTypes>
          <NotificationType>
            <RMText type="sans" bold size="xs" color="on-surface-primary">
              EMAIL
            </RMText>
          </NotificationType>
          <NotificationType>
            <RMText type="sans" bold size="xs" color="on-surface-primary">
              TEXT
            </RMText>
          </NotificationType>
        </NotificationTypes>
      </NotificationsHeader>

      <NotificationsRows>
        {notificationFormItems.map(({ label, description, path, hidePhone, extraDescription }) => (
          <NotificationsRow key={path}>
            <NotificationRowDescriptions>
              <RMText type="sans" size="s" bold color="on-surface-primary">
                {label}
              </RMText>
              <RMText type="sans" size="s" color="on-surface-secondary">
                {description}
              </RMText>
              {isMobile == false && extraDescription != null ? (
                <>
                  <RMSpacer direction="column" spacing="xs" />
                  {extraDescription}
                </>
              ) : null}
            </NotificationRowDescriptions>
            <NotificationTypes>
              <NotificationType>
                <InputController form={form} path={`email.${path}`}>
                  {(props) => (
                    <RMCheckbox
                      square
                      checked={props.value ?? false}
                      onChange={props.onChange}
                      label={isMobile ? 'Email' : null}
                    />
                  )}
                </InputController>
              </NotificationType>
              {hidePhone ? (
                <div style={{ width: '4.25rem' }} />
              ) : (
                <NotificationType>
                  <InputController form={form} path={`phone.${path}`}>
                    {(props) => (
                      <RMCheckbox
                        square
                        checked={props.value ?? false}
                        onChange={props.onChange}
                        label={isMobile ? 'Text' : null}
                        disabled={noPhone}
                      />
                    )}
                  </InputController>
                </NotificationType>
              )}
            </NotificationTypes>
            {isMobile && extraDescription != null ? (
              <>
                {/* Add a extra gap */}
                <div />
                {extraDescription}
              </>
            ) : null}
          </NotificationsRow>
        ))}
      </NotificationsRows>
    </Container>
  );
}
