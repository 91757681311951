import WordmarkSrc from '@/assets/wordmark-primary.svg';
import { RMBackButton } from '@/components/RMBackButton';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { genitiveCase } from '@/utils/genitiveCase';

import { Body, Buttons, Header, LeftPanel, RightPanel, StyledPage } from './OnboardingPromptsReview.styles';
import { OnboardingPromptsReviewProps } from './OnboardingPromptsReview.types';

export function OnboardingPromptsReview({
  isCurrentUserTheStoryteller,
  storytellerFirstName,
  nextDisabled,
  showAddMore,
  onAddMore,
  onNext,
  onBack,
  PromptList,
}: OnboardingPromptsReviewProps) {
  return (
    <StyledPage>
      <Header>
        <img src={WordmarkSrc} alt="Remento wordmark" />
      </Header>

      <Body>
        <LeftPanel>
          <RMBackButton onClick={onBack} />
          <RMSpacer spacing="2xl" direction="column" />
          <RMText type="serif" size="xl" color="on-surface-primary">
            {!isCurrentUserTheStoryteller && storytellerFirstName != null
              ? `Review ${genitiveCase(storytellerFirstName)} prompts`
              : 'Review prompts'}
          </RMText>
          <RMSpacer spacing="xl" direction="column" />
          <RMText type="sans" size="s" color="on-surface-primary">
            Click to edit or drag to reorder. You can edit these later.
          </RMText>
          <RMSpacer spacing="2xl" direction="column" />
          <Buttons>
            {showAddMore && <RMButton onClick={onAddMore}>Add more</RMButton>}
            <RMButton background="primary" disabled={nextDisabled} onClick={onNext} autoLoading>
              Next
            </RMButton>
          </Buttons>
        </LeftPanel>
        <RightPanel>{PromptList}</RightPanel>
      </Body>
    </StyledPage>
  );
}
