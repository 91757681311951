import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { faRightFromBracket } from '@fortawesome/pro-solid-svg-icons';

import { PageLoader } from '@/components/PageLoader/PageLoader';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMPage } from '@/components/RMPage';
import { RMSettings } from '@/components/RMSettings';
import { toast } from '@/components/RMToast/RMToast';
import { useAutohideIntercomBubble } from '@/hooks/useAutohideIntercomBubble';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { getQueryParam } from '@/hooks/useQueryParam';
import { AppLayout } from '@/layouts/AppLayout';
import { NotificationsSettingsPreview } from '@/modules/account-settings/components/NotificationSettingsPreview/NotificationSettingsPreview';
import { MyProfileSettingsPreviewContainer } from '@/modules/account-settings/containers/MyProfileSettingsPreview.container';
import { MyProjectsSettingsPreviewContainer } from '@/modules/account-settings/containers/MyProjectsSettingsPreview.container';
import { NotificationsSettingsEditContainer } from '@/modules/account-settings/containers/NotificationSettingsEdit.container';
import { ProfileEditContainer } from '@/modules/account-settings/containers/ProfileEdit.container';
import {
  closeAccountSettingsDialog,
  createAccountSettingsDialogPanelStore,
  openEditNotificationsSettingsDialog,
  openEditProfileDialog,
  useAccountSettingsDialogPanelState,
} from '@/modules/account-settings/states/account-settings-dialog-panel.state';
import { MobilePageHeaderContainer } from '@/modules/navbar/containers/MobilePageHeaderContainer';
import { RementoPage } from '@/modules/routing';
import { useSignOut, useUser } from '@/services/api/auth/auth.service.hook';

import { Container } from './AccountSettingsPage.styles';

function AccountSettings() {
  const isMobile = useIsMobileViewport();
  const [searchParams] = useSearchParams();
  useAutohideIntercomBubble();

  const user = useUser();
  const personId = user?.personId;
  const signOut = useSignOut();

  const dialogStore = useMemo(() => createAccountSettingsDialogPanelStore(), []);
  const dialogState = useAccountSettingsDialogPanelState(dialogStore);

  useEffect(() => {
    if (personId == null) {
      return;
    }

    const panel = searchParams.get('panel');
    switch (panel) {
      case 'contact':
        openEditProfileDialog(dialogStore, personId);
        break;
      case 'notifications':
        openEditNotificationsSettingsDialog(dialogStore, personId);
        break;
    }
  }, [dialogStore, personId, searchParams]);

  useEffect(() => {
    if (getQueryParam('change-payment-method-success') !== 'true') {
      return;
    }
    toast('Your payment method has been updated!');
  }, []);

  if (!personId) {
    return <PageLoader />;
  }

  return (
    <RMPage.Root>
      <MobilePageHeaderContainer />
      <RMPage.Header>
        <RMPage.Title title="My account">
          <RMButton leftIcon={faRightFromBracket} onClick={() => signOut(true)}>
            Log Out
          </RMButton>
        </RMPage.Title>
      </RMPage.Header>
      <RMPage.Content>
        <Container>
          <MyProfileSettingsPreviewContainer
            personId={user?.personId}
            onEdit={() => openEditProfileDialog(dialogStore, user?.personId)}
          />

          {user.subscription != null && (
            <RMSettings.Section>
              <RMSettings.SectionTitle title="Subscription details" columns={isMobile ? 12 : 4} />
              <RMSettings.SectionContent columns={isMobile ? 12 : 8}>
                <MyProjectsSettingsPreviewContainer />
              </RMSettings.SectionContent>
            </RMSettings.Section>
          )}

          <RMSettings.Section>
            <RMSettings.SectionTitle title="Notification settings" columns={isMobile ? 12 : 4} />
            <RMSettings.SectionContent columns={isMobile ? 12 : 8}>
              <NotificationsSettingsPreview onEdit={() => openEditNotificationsSettingsDialog(dialogStore, personId)} />
            </RMSettings.SectionContent>
          </RMSettings.Section>
        </Container>

        <ProfileEditContainer
          open={dialogState.type === 'edit-profile'}
          personId={dialogState.type === 'edit-profile' ? dialogState.personId : null}
          isStoryteller={false}
          onClose={() => closeAccountSettingsDialog(dialogStore)}
        />
        <NotificationsSettingsEditContainer
          open={dialogState.type === 'edit-notification-settings'}
          projectId={null}
          personId={dialogState.type === 'edit-notification-settings' ? dialogState.personId : null}
          onClose={() => closeAccountSettingsDialog(dialogStore)}
        />
      </RMPage.Content>
    </RMPage.Root>
  );
}

export function AccountSettingsPage() {
  return (
    <RementoPage type="default">
      <AppLayout>
        <AccountSettings />
      </AppLayout>
    </RementoPage>
  );
}
