import { useMemo } from 'react';
import { AclGroupRole } from '@remento/types/acl';
import { BaseAssetAlternativeType, ImageAssetAlternativeType } from '@remento/types/alternative';
import { EntityType } from '@remento/types/entity';
import { Project, Prompt, PromptStatus, PromptType } from '@remento/types/project';
import { hasIntersection } from '@remento/utils/array/has-intersection';
import { notNull } from '@remento/utils/array/notNull';
import { useQuery } from '@tanstack/react-query';

import { useCollection, useCollectionEntityData, useEntity } from '@/hooks/useQuery';
import { useServices } from '@/Services';

import { useCurrentUserAclGroupMembers, usePrimaryAclGroup } from '../acl';
import { useAlternativeFileUrl, useAlternativeQuery, useAlternativeType, useAssetAlternativesQuery } from '../asset';
import { useUser } from '../auth/auth.service.hook';
import { usePersonQuery } from '../person';

export function useProjectsQuery() {
  const { projectService } = useServices();
  return useCollection(EntityType.PROJECT, {}, (context) => projectService.getProjects(context));
}

export function useProjectsItems(role?: AclGroupRole | null) {
  const { projectService } = useServices();

  const userAclMembers = useCurrentUserAclGroupMembers();
  const allProjectsQuery = useProjectsQuery();
  const allProjects = useCollectionEntityData(allProjectsQuery.data, EntityType.PROJECT, (id) =>
    projectService.getProject(id),
  );

  return useMemo(() => {
    if (role == null) {
      return allProjects;
    }

    return allProjects?.filter((p) => {
      const member = userAclMembers?.find((m) => hasIntersection(m.groupRefIds, ...p.acl));
      return member?.role === role;
    });
  }, [allProjects, role, userAclMembers]);
}

export function useProjectQuery(projectId: string | null | undefined) {
  const { projectService } = useServices();
  return useEntity(EntityType.PROJECT, projectId, (id) => projectService.getProject(id));
}

export function useProjectInviteLink(projectId: string | null) {
  const { aclService } = useServices();

  const projectQuery = useProjectQuery(projectId);
  const projectAclGroup = usePrimaryAclGroup(projectQuery.data?.acl, EntityType.PROJECT);

  return useQuery({
    enabled: projectAclGroup != null,
    queryKey: ['project-invite-link', projectId].filter(notNull),
    queryFn: async () => {
      // The id will never be null here.
      return aclService.generateInviteLink(projectAclGroup?.id ?? '');
    },
  });
}

export function useProjectInitials(project: Project | null | undefined): string {
  return useMemo(() => project?.name?.[0] ?? 'N/A', [project]);
}

export function useIsCurrentUserTheStoryteller(projectId: string | null): boolean | null {
  const user = useUser();
  const personQuery = usePersonQuery(user?.personId);
  const projectQuery = useProjectQuery(projectId);

  return useMemo(() => {
    if (user === null) {
      return false;
    }
    if (user === undefined || projectQuery.data == null || personQuery.data == null) {
      return null;
    }
    return projectQuery.data.notifications.recipientPersonIds.some((personId) =>
      personQuery.data.refIds.includes(personId),
    );
  }, [personQuery.data, projectQuery.data, user]);
}

export function useProjectCoverUrl(project: Project | null | undefined): string | null {
  const alternativesQuery = useAssetAlternativesQuery(project?.coverAssetId);
  const alternativeQuery = useAlternativeQuery(alternativesQuery.data?.[0] ?? null);

  return useAlternativeFileUrl(alternativeQuery.data?.id);
}

export function useProjectPromptsQuery(projectId: string | null) {
  const { projectService } = useServices();
  return useCollection(EntityType.PROMPT, projectId ? { projectId } : null, (params, scope) =>
    projectService.getProjectPrompts(params.projectId, scope),
  );
}

export function useFilterPromptIds(promptIds: string[] | null | undefined, status: PromptStatus) {
  const { projectService } = useServices();
  const prompts = useCollectionEntityData(promptIds, EntityType.PROMPT, (id) => projectService.getPrompt(id));
  return useMemo(() => prompts?.filter((p) => p.status === status).map((p) => p.id), [prompts, status]);
}

export function useFilterProjectPromptIds(projectId: string | null, status: PromptStatus) {
  const projectPromptsQuery = useProjectPromptsQuery(projectId);
  return useFilterPromptIds(projectPromptsQuery.data, status);
}

export function useSortedPromptIds(ids: string[] | null, field: 'createdAt', order: 'asc' | 'desc') {
  const { projectService } = useServices();
  const prompts = useCollectionEntityData(ids, EntityType.PROMPT, (id) => projectService.getPrompt(id));
  return useMemo(() => {
    return prompts
      ?.sort((a, b) => {
        switch (field) {
          case 'createdAt': {
            return order === 'asc'
              ? a.audit.create.date - b.audit.create.date
              : b.audit.create.date - a.audit.create.date;
          }
        }
      })
      .map((p) => p.id);
  }, [field, order, prompts]);
}

export function usePromptQuery(promptId: string | null) {
  const { projectService } = useServices();
  return useEntity(EntityType.PROMPT, promptId, (id, scope) => projectService.getPrompt(id, scope));
}

export function usePromptQuestionsQuery(promptId: string | null) {
  const { projectService } = useServices();
  return useCollection(EntityType.QUESTION, promptId ? { promptId } : null, (params, scope) =>
    projectService.getPromptQuestions(params.promptId, scope),
  );
}

export function useQuestionQuery(questionId: string | null) {
  const { projectService } = useServices();
  return useEntity(EntityType.QUESTION, questionId, (id, scope) => projectService.getQuestion(id, scope));
}

export function usePromptFirstQuestionQuery(promptId: string | null) {
  const questions = usePromptQuestionsQuery(promptId);
  return useQuestionQuery(questions.data?.[0] ?? null);
}

export function usePromptImageUrl(prompt: Prompt | null, type: ImageAssetAlternativeType) {
  const alternativesQuery = useAssetAlternativesQuery(prompt?.type === PromptType.PHOTO ? prompt?.imagesIds[0] : null);
  const alternative = useAlternativeType(alternativesQuery.data, type);

  // When creating the prompt, the smaller assets alternatives will not be available because
  // they are generated asynchronously.
  // In that case, fallback to the original alternative.
  const fallbackAlternative = useAlternativeType(alternativesQuery.data, BaseAssetAlternativeType.ORIGINAL);

  return useAlternativeFileUrl(alternative?.id ?? fallbackAlternative?.id ?? null);
}
