export type InputValue = string | number | boolean | null | undefined | FileList;

/**
 * Return true if the supplied value should be traversed for merging or kept as is.
 * @param value
 */
export function isInputValue(value: any): value is InputValue {
  if (value == null) {
    return true;
  }
  switch (typeof value) {
    case 'boolean':
    case 'number':
    case 'string':
    case 'undefined':
      return true;
    case 'function':
    case 'symbol':
    case 'bigint':
      return false;
    case 'object':
      return value instanceof FileList || value instanceof Set;
  }
}
