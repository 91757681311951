import { useMemo } from 'react';

import { useInputValue } from '@/modules/form/input';
import { usePersonQuery } from '@/services/api/person';
import { useIsCurrentUserTheStoryteller, useProjectQuery } from '@/services/api/project';
import { formatTimeWithTimezone, getCurrentTimezoneInfo, Weekday } from '@/utils/tz';

import { PromptSettingsForm } from '../forms/prompt-settings-form';

export interface TextChunk {
  id: string;
  value: string;
  bold?: boolean;
}

export function usePromptFrequencyText(
  projectId: string,
  time: string | null,
  weekdays: Set<Weekday> | null,
): TextChunk[] {
  const projectQuery = useProjectQuery(projectId);
  const storytellerPersonQuery = usePersonQuery(projectQuery.data?.subjectPersonIds[0]);
  const storytellerFirstName = storytellerPersonQuery.data?.name?.first ?? null;
  const isCurrentUserTheStoryteller = useIsCurrentUserTheStoryteller(projectId);

  const formattedWeekdays = useMemo(() => {
    if (weekdays == null) {
      return '';
    }

    const listFormat = new Intl.ListFormat('en', { style: 'long' });
    const allWeekdays = Object.values(Weekday);
    return listFormat.format(
      Array.from(weekdays).sort((a, b) => {
        const aIndex = allWeekdays.indexOf(a);
        const bIndex = allWeekdays.indexOf(b);
        return aIndex - bIndex;
      }),
    );
  }, [weekdays]);
  const formattedTime = useMemo(() => {
    if (time == null || time == '') {
      return 'N/A';
    }
    const tz = getCurrentTimezoneInfo();
    return formatTimeWithTimezone(time, tz);
  }, [time]);

  return [
    { id: '0', value: isCurrentUserTheStoryteller ? 'You' : storytellerFirstName ?? 'They' },
    { id: '1', value: ' will receive prompts from Remento' },
    { id: '2', value: formattedWeekdays.length ? ' every ' : ' ' },
    { id: '3', value: formattedWeekdays, bold: true },
    { id: '4', value: ' at ' },
    { id: '5', value: formattedTime, bold: true },
    { id: '6', value: '.' },
  ];
}

export function usePromptFrequencyTextByForm(projectId: string, form: PromptSettingsForm): TextChunk[] {
  const time = useInputValue(form, 'time');
  const weekdays = useInputValue(form, 'weekdays');
  return usePromptFrequencyText(projectId, time, weekdays);
}
