import { useCallback, useMemo } from 'react';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';
import { useServices } from '@/Services';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { CustomOnboardingDialogRenderProps } from '@/services/local/onboarding-dialog';
import { captureException } from '@/utils/captureException';
import { getCurrentTimezoneInfo, getTimezoneInfo } from '@/utils/tz';

export function TimezoneUpdateOnboardingDialogContainer({ onSetOnboardingDone }: CustomOnboardingDialogRenderProps) {
  const { entityCacheManagerService, userService } = useServices();
  const user = useUser();

  const currenTimezone = useMemo(() => {
    // This will never happen. This dialog is only shown to user with a timezone.
    if (user?.timezone == null) {
      return null;
    }
    return getTimezoneInfo(user.timezone);
  }, [user?.timezone]);
  const newTimezone = useMemo(() => getCurrentTimezoneInfo(), []);

  const handleClose = useCallback(async () => {
    try {
      await onSetOnboardingDone();
    } catch (error) {
      captureException(error, true);
      toast('An unexpected error has occurred.', 'root-toast', 'error');
    }
  }, [onSetOnboardingDone]);

  const handleUpdate = useCallback(async () => {
    if (user == null) {
      return;
    }

    try {
      await entityCacheManagerService.mutate([...userService.createSetUserTimezoneMutation(user, newTimezone.id)]);
      await userService.refreshUser();
      await onSetOnboardingDone();
    } catch (error) {
      captureException(error, true);
      toast('An unexpected error has occurred.', 'root-toast', 'error');
    }
  }, [entityCacheManagerService, newTimezone.id, onSetOnboardingDone, user, userService]);

  return (
    <RMDialog.Root open onClose={handleClose}>
      <RMDialog.Content>
        <RMDialog.Header
          title="Would you like to update your time zone?"
          rightAdornment={<RMCloseButton onClick={handleClose} />}
        />

        <RMDialog.Body>
          <RMText type="sans" size="xs" color="on-surface-primary">
            Remento uses your time zone to send new prompts and other useful reminders. To ensure we are sending these
            at a good time of day, would you like to update to a new time zone?
            <br />
            <br />
            Current: {currenTimezone?.displayName}
            <br />
            <br />
            <b>NEW:</b> {newTimezone.displayName}
            <br />
            <br />
            To update this in the future, visit “My account”.
          </RMText>
        </RMDialog.Body>

        <RMDialog.Footer>
          <RMButton background="neutral" autoLoading onClick={handleClose}>
            No, thanks
          </RMButton>
          <RMButton background="primary" autoLoading onClick={handleUpdate}>
            Yes, update
          </RMButton>
        </RMDialog.Footer>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
