import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { PromptType } from '@remento/types/project';

interface OnboardingContextState {
  promptsType: PromptType | null;
  setPromptsType: (promptTypes: PromptType) => void;
}

const OnboardingContext = createContext<OnboardingContextState | null>(null);

export type OnboardingContextProviderProps = PropsWithChildren<unknown>;

export function OnboardingContextProvider({ children }: OnboardingContextProviderProps) {
  const [promptsType, setPromptsType] = useState<PromptType | null>(null);

  const value = useMemo(() => ({ promptsType, setPromptsType }), [promptsType]);

  return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>;
}

// eslint-disable-next-line react-refresh/only-export-components
export function useOnboardingContext(): OnboardingContextState {
  const context = useContext(OnboardingContext);
  if (!context) {
    throw new Error('useOnboardingContext must be used inside a OnboardingContextProvider');
  }
  return context;
}
