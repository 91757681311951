import { styled } from '@linaria/react';

export const CommonPageConfig = `
  @page {
    height: 860px;
    width: 1506px;
  }

  body {
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
  }
`;

export const BiographyPageConfig = `
  body {
    background: linear-gradient(180deg, #253838 0%, #141F1F 100%);
  }
`;

export const BabybookPageConfig = `
  body {
    background: linear-gradient(73.1deg, #E2E9FC 0.77%, #D0DAFB 99.19%);
  }
`;

export const GiftCardRoot = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`;

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  flex: 0 0 760px;
`;

export const BiographyImage = styled.img`
  position: absolute;
  width: 875px;
  height: 627.57px;
  transform: rotate(10.49deg);
  left: -56px;
  max-width: unset;
`;

export const BabybookImage = styled.img`
  position: absolute;
  width: 875px;
  height: 627.57px;
  left: -56px;
  max-width: unset;
`;

export const GiftCardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding-right: 24px;
`;

export const WordmarkImage = styled.img`
  height: 40px;
  margin-bottom: 20px;
`;

export const TitleText = styled.span`
  font-family: 'Adonis';
  font-size: 100px;
  line-height: 140px;
  text-align: center;
  margin-bottom: 20px;

  &[data-theme='dark'] {
    color: white;
  }
  &[data-theme='light'] {
    color: #1c1c1c;
  }
`;

export const DescriptionText = styled.span`
  display: flex;
  align-items: center;
  font-family: 'FKGroteskNeue';
  font-size: 30px;
  line-height: 35px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 56px;
  min-height: 105px;

  &[data-theme='dark'] {
    color: white;
  }
  &[data-theme='light'] {
    color: #1c1c1c;
  }
`;

export const CouponWrapper = styled.div`
  display: flex;
  border-radius: 12px;
  padding: 14px 0;
  background-color: white;
  width: 498px;
  justify-content: center;
  margin-bottom: 18px;
`;

export const CouponText = styled.span`
  font-family: 'Adonis';
  font-size: 50px;
  line-height: 70px;
  color: black;
`;

export const VisitText = styled.span`
  font-family: 'FKGroteskNeue';
  font-size: 19x;
  line-height: 23px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 45px;

  &[data-theme='dark'] {
    color: white;
  }
  &[data-theme='light'] {
    color: #1c1c1c;
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: row;
  gap: 23px;
`;

export const QrCodeWrapper = styled.a`
  border: 4px solid #233d33;
  padding: 14px;
  border-radius: 19px;
  background-color: white;
  flex: 0 0 143px;
  height: 143px;
`;

export const FooterText = styled.span`
  font-family: 'FKGroteskNeue';
  font-size: 13x;
  line-height: 140%;
  opacity: 0.7;

  &[data-theme='dark'] {
    color: white;
  }
  &[data-theme='light'] {
    color: #1c1c1c;
  }
`;
