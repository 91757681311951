import { Outlet } from 'react-router-dom';

import { PromptDraftsStoreProvider } from '@/modules/project/states/prompt-drafts.state.context';
import { RementoPage } from '@/modules/routing';

import { OnboardingContextProvider } from '../onboarding.context';

export function OnboardingContainer() {
  return (
    <RementoPage type="default">
      <PromptDraftsStoreProvider>
        <OnboardingContextProvider>
          <Outlet />
        </OnboardingContextProvider>
      </PromptDraftsStoreProvider>
    </RementoPage>
  );
}
