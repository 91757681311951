import { useMemo } from 'react';
import { UserStatus } from '@remento/types/user';

import { usePersonQuery } from '@/services/api/person';
import { usePersonUserQuery } from '@/services/api/user/user.service.hook';
import { formatPhoneNumber } from '@/utils/phone-number';

import { RecipientSettingsPreviewSection } from '../components/RecipientSettingsPreviewSection/RecipientSettingsPreviewSection';

interface RecipientSettingsPreviewContainerProps {
  personId: string;
  onEdit: () => void;
  onManageNotification: () => void;
}

export function RecipientSettingsPreviewContainer({
  personId,
  onEdit,
  onManageNotification,
}: RecipientSettingsPreviewContainerProps) {
  const personUserQuery = usePersonUserQuery(personId);
  const personQuery = usePersonQuery(personId);

  const email = personUserQuery.data?.communicationChannels.email ?? '';
  const phone = useMemo(
    () => formatPhoneNumber(personUserQuery.data?.communicationChannels.phone),
    [personUserQuery.data?.communicationChannels.phone],
  );

  return (
    <RecipientSettingsPreviewSection
      name={personQuery.data?.name?.full}
      email={email}
      phone={phone}
      timezone={personUserQuery.data?.timezone ?? null}
      canEdit={personUserQuery.data?.status === UserStatus.PLACEHOLDER}
      onEdit={onEdit}
      onManageNotification={onManageNotification}
    />
  );
}
