import { PropsWithChildren } from 'react';

import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { useRMDialog } from './RMDialog.context';
import { Content } from './RMDialogContent.styles';

export type RMDialogContentProps = PropsWithChildren<{
  className?: string;
}>;

export function RMDialogContent({ className, children }: RMDialogContentProps) {
  const { variant } = useRMDialog();
  const mobile = useIsMobileViewport();

  const screenVariant = mobile ? `${variant}-mobile` : variant;
  const animation = mobile && variant !== 'full-screen' ? 'slide' : 'fade-in';

  return (
    <Content
      key={mobile ? 'mobile' : 'desktop'}
      initial={animation === 'slide' ? { translateY: '100%' } : { opacity: 0 }}
      animate={animation === 'slide' ? { translateY: '0' } : { opacity: 1 }}
      transition={{ duration: 0.25, ease: 'easeOut' }}
      data-mobile={mobile}
      data-variant={screenVariant}
      className={className}
    >
      {children}
    </Content>
  );
}
