import { useCallback, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import lottie, { AnimationItem } from 'lottie-web';

import ThinkingLottieAnimation from '@/@assets/lottie.thinking.json';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';
import { useServices } from '@/Services';
import { captureException } from '@/utils/captureException';

import { Animation, Content, Footer } from './InvalidEmailLinkModal.styles';

export interface InvalidEmailLinkModalProps {
  open: boolean;
  onClose: () => void;
}

export function InvalidEmailLinkModal({ open, onClose }: InvalidEmailLinkModalProps) {
  const { authService } = useServices();

  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const [emailSent, setEmailSent] = useState(false);

  const resendLink = useCallback(async () => {
    try {
      if (!email) {
        throw new Error('Email not provided');
      }

      await authService.sendEmailSignInLink(email);
      setEmailSent(true);
      toast('Email sign in link sent!', 'root-toast', 'default');
    } catch (error) {
      toast('Failed to send email sign in link', 'root-toast', 'error');
      captureException(error, true);
    }
  }, [authService, email]);

  const animationRef = useRef<AnimationItem | null>();
  const lottieRef = useCallback((node: HTMLDivElement | null) => {
    if (node === null) {
      animationRef.current?.destroy();
      animationRef.current = null;
      return;
    }

    const animation = lottie.loadAnimation({
      container: node,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: ThinkingLottieAnimation,
    });
    animationRef.current = animation;
  }, []);

  return (
    <RMDialog.Root open={open} onClose={onClose}>
      <RMDialog.Content>
        <RMDialog.Header rightAdornment={<RMCloseButton onClick={onClose} />} />
        <RMDialog.Body>
          <Content>
            <Animation ref={lottieRef} />
            <RMText type="sans" size="s" color="on-surface-primary">
              This login link for <b>{email}</b> has already been used. Click 'Resend' to get a new link in your email.
            </RMText>
          </Content>
        </RMDialog.Body>

        <RMDialog.Footer>
          <Footer>
            <RMButton background="neutral" fullWidth autoLoading disabled={emailSent} onClick={resendLink}>
              Resend
            </RMButton>
          </Footer>
        </RMDialog.Footer>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
