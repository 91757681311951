import { styled } from '@linaria/react';

export const StyledEmbeddedTypeform = styled.div`
  &[data-fullscreen='true'] {
    width: 100dvw;
    height: 100dvh;

    & iframe {
      border-radius: 0 !important;
      width: 100dvw !important;
      height: 100dvh !important;
    }
  }
`;
