import { useMemo } from 'react';

import { RMLink } from '@/components/RMLink/RMLink';
import { RMSettings } from '@/components/RMSettings';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { getTimezoneInfo } from '@/utils/tz';

import { Links, RecipientInfo, Row } from './RecipientSettingsPreviewSection.styles';

export interface RecipientSettingsPreviewSectionProps {
  name?: string;
  email: string;
  phone: string | null;
  timezone: string | null;
  canEdit: boolean;
  onEdit: () => void;
  onManageNotification: () => void;
}

export function RecipientSettingsPreviewSection({
  name,
  email,
  phone,
  timezone,
  canEdit,
  onEdit,
  onManageNotification,
}: RecipientSettingsPreviewSectionProps) {
  const isMobile = useIsMobileViewport();

  const timezoneInfo = useMemo(() => {
    if (timezone == null) {
      return null;
    }
    return getTimezoneInfo(timezone);
  }, [timezone]);

  return (
    <RMSettings.Section>
      <RMSettings.SectionTitle title="Storyteller" columns={isMobile ? 12 : 4} />
      <RMSettings.SectionContent columns={isMobile ? 12 : 8}>
        <Row data-mobile={isMobile}>
          <RecipientInfo>
            <RMText type="sans" size="s" bold color="on-surface-primary">
              {name}
            </RMText>
            <RMText type="sans" size="s" color="on-surface-secondary">
              {email}
            </RMText>
            {phone && (
              <RMText type="sans" size="s" color="on-surface-secondary">
                {phone}
              </RMText>
            )}
            {timezoneInfo != null && (
              <RMText type="sans" size="s" color="on-surface-secondary">
                Time Zone: {timezoneInfo.displayName}
              </RMText>
            )}
            {canEdit && (
              <Links>
                <RMLink onClick={onEdit}>Edit storyteller details</RMLink>
                <RMLink onClick={onManageNotification}>Manage notification settings</RMLink>
              </Links>
            )}
          </RecipientInfo>
        </Row>
      </RMSettings.SectionContent>
    </RMSettings.Section>
  );
}
