import { Colors } from '@/styles/base/colors';

import { RoutePath } from './types/routing.types';

export const INTERCOM_WIDGET_ALLOWLIST: RoutePath[] = [
  // Questionnaire
  RoutePath.Questionnaire,
  // Checkout
  RoutePath.Checkout,
  RoutePath.CheckoutAudience,
  RoutePath.CheckoutBiographyContact,
  RoutePath.CheckoutBiographyDelivery,
  RoutePath.CheckoutBiographyReview,
  RoutePath.CheckoutAutobiographyContact,
  RoutePath.CheckoutAutobiographyReview,
  RoutePath.CheckoutBaby,
  RoutePath.CheckoutBabyAudience,
  RoutePath.CheckoutBabyHost,
  RoutePath.CheckoutBabyProject,
  RoutePath.CheckoutBabyReview,
  RoutePath.CheckoutFreeDetails,
  RoutePath.CheckoutFreeReview,
  RoutePath.CheckoutBundle,
  RoutePath.CheckoutGift,
  RoutePath.CheckoutSurvey,
  RoutePath.CheckoutGetStarted,
  // Onboarding
  RoutePath.Onboarding,
  RoutePath.OnboardingSignin,
  RoutePath.OnboardingIntro,
  RoutePath.SetupPromptTypeSelection,
  RoutePath.SetupTextPromptSelection,
  RoutePath.SetupPromptsReview,
  RoutePath.SetupPerspective,
  RoutePath.SetupCollaborators,
  // Stories
  RoutePath.Stories,
  // Prompts
  RoutePath.Questions,
  // My account
  RoutePath.AccountSettings,
  // Project settings
  RoutePath.ProjectSettings,
  // New projects
  RoutePath.NewProject,
  // Subscription offer
  RoutePath.AccountRenewPromo,
  RoutePath.AccountReactivate,
  // Sign-in
  RoutePath.Signin,
  // Catchup
  RoutePath.ProjectRecord,
  RoutePath.ProjectRecordCustomize,
  // Book order
  RoutePath.BookOrderCoverDesign,
  RoutePath.BookOrderStories,
  RoutePath.BookOrderPreview,
  RoutePath.BookOrderQuantity,
  RoutePath.BookOrderFinalize,
  RoutePath.BookOrderComplete,
  // Book reorder
  RoutePath.BookReorderPreview,
  RoutePath.BookReorderQuantity,
  RoutePath.BookReorderFinalize,
];

export const ONBOARDING_DIALOG_BLACKLIST: RoutePath[] = [
  RoutePath.Stories,
  RoutePath.Questions,
  RoutePath.ProjectSettings,
];

export const ROUTE_THEME: Partial<Record<RoutePath, string>> = {
  [RoutePath.ProjectRecord]: Colors.neutral['93'],
  [RoutePath.ProjectRecordCustomize]: Colors.neutral['93'],
  [RoutePath.RecordingTypeSelection]: Colors.neutral['93'],
  [RoutePath.Recording]: Colors.neutral['93'],
  [RoutePath.RecordingIntro]: Colors.spruce['10'],
  [RoutePath.RecordingFinish]: Colors.spruce['10'],
  [RoutePath.RecordingPromptRecorded]: Colors.spruce['10'],
  [RoutePath.RecordingRecovery]: Colors.neutral['93'],
};
