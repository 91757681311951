import { useEffect, useMemo } from 'react';

import { getCurrentTimezoneInfo } from '@/utils/tz';

import { BiographyProjectCheckoutDeliveryForm } from '../components/BiographyProjectCheckoutDeliveryForm/BiographyProjectCheckoutDeliveryForm';
import { goToStep } from '../project-checkout.manager';
import { useProjectCheckoutManager } from '../project-checkout.manager.context';

export function BiographyProjectCheckoutDeliveryStepContainer() {
  const manager = useProjectCheckoutManager();

  const timezone = useMemo(() => getCurrentTimezoneInfo().shortAbbreviation, []);

  useEffect(() => {
    goToStep(manager, 'delivery');
  }, [manager]);

  return <BiographyProjectCheckoutDeliveryForm form={manager.form} timezone={timezone} />;
}
