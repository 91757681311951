import { useMemo } from 'react';
import { ProjectStatus } from '@remento/types/project';

import { usePersonQuery } from '@/services/api/person';
import { parseProjectCronString } from '@/services/api/project/project.utils';

import { useIsCurrentUserTheStoryteller, useProjectQuery } from '../../../services/api/project';
import { PromptSettingsPreview } from '../components/PromptSettingsPreview/PromptSettingsPreview';
import { usePromptFrequencyText } from '../hooks/usePromptFrequencyText';

export interface PromptSettingsPreviewContainerProps {
  projectId: string;
  onEdit: () => void;
}

export function PromptSettingsPreviewContainer({ projectId, onEdit }: PromptSettingsPreviewContainerProps) {
  const projectQuery = useProjectQuery(projectId);
  const recipientPersonQuery = usePersonQuery(projectQuery.data?.notifications.recipientPersonIds?.[0]);
  const recipientPerson = recipientPersonQuery.data;
  const isCurrentUserTheStoryteller = useIsCurrentUserTheStoryteller(projectId);

  const parsedProjectCron = useMemo(() => {
    if (projectQuery.data == null) {
      return null;
    }
    return parseProjectCronString(projectQuery.data.notifications.interval);
  }, [projectQuery.data]);
  const promptFrequencyText = usePromptFrequencyText(
    projectId,
    parsedProjectCron?.localTime ?? null,
    parsedProjectCron?.weekdays ?? null,
  );

  if (!recipientPerson || !projectQuery.data) {
    return null;
  }
  if (projectQuery.data.status !== ProjectStatus.ACTIVE && projectQuery.data.status !== ProjectStatus.PAUSED) {
    return null;
  }

  return (
    <PromptSettingsPreview
      storytellerFirstName={recipientPerson.name?.first ?? 'They'}
      promptFrequencyText={promptFrequencyText}
      isCurrentUserTheStoryteller={isCurrentUserTheStoryteller ?? false}
      isPaused={projectQuery.data.status === ProjectStatus.PAUSED}
      onEdit={onEdit}
    />
  );
}
