import { css } from '@linaria/core';
import { styled } from '@linaria/react';

export const StyledSearchSelect = styled.div`
  display: flex;
  flex-flow: column;
  gap: var(--spacing-xs);
  width: 100%;
`;

export const SearchSelectError = styled.span`
  font-size: 0.875rem;
  font-family: var(--font-sans);
  color: var(--danger);
`;

export const selectContainerClassName = css`
  display: flex;
  align-items: center;
  width: 100%;
  height: 3rem;
  padding: 1px calc(var(--spacing-md) + 1px);
  padding-left: calc(var(--spacing-md) + 1px);
  background-color: var(--surface);
  border: 1px solid var(--border-strong);
  border-radius: var(--radius-regular);
  font-size: 1rem;
  font-family: var(--font-sans);
  color: var(--on-surface-secondary);
  outline: none;

  ::placeholder {
    color: var(--on-surface-tertiary);
  }

  :hover {
    border: 1px solid var(--border-stronger);
  }

  :focus {
    padding: 0 var(--spacing-md);
    padding-left: var(--spacing-md);
    border: 1px solid var(--border-primary);
    border-width: 2px;
  }
`;

export const selectControlClassName = css`
  width: 100%;
`;

export const selectMenuClassName = css`
  background-color: var(--surface);
  border-radius: var(--radius-round);
  box-shadow: var(--elevation-bottom-3);
  padding: var(--spacing-xs) 0;
  border: 1px solid var(--border-hairline);
  left: 0;
`;

export const selectMenuListClassName = css`
  position: relative;
  padding: var(--spacing-xs) var(--spacing-sm);
  cursor: pointer;
  font-weight: 700;

  &:after {
    position: absolute;
    content: ' ';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: 100ms;
  }

  :hover {
    &:after {
      background-color: var(--darken-neutral-hover);
    }
  }
`;
