import { useMemo } from 'react';

import { RMSearchSelect, RMSearchSelectOption } from '@/components/RMSearchSelect/RMSearchSelect';
import { getAllTimezonesIds, getTimezoneInfo } from '@/utils/tz';

export interface TimezoneSelectProps {
  label?: string;
  value?: string | null;
  error?: string;
  onChange?: (value: string | null) => void;
}

export function TimezoneSelect({ label, value, error, onChange }: TimezoneSelectProps) {
  const options = useMemo<RMSearchSelectOption[]>(() => {
    const allTimezonesIds = getAllTimezonesIds();
    const topTimezonesIds = [
      'America/Los_Angeles',
      'America/Phoenix',
      'America/Denver',
      'America/Chicago',
      'America/New_York',
      'America/Vancouver',
      'America/Edmonton',
      'America/Regina',
      'America/Winnipeg',
      'America/Toronto',
      'America/Halifax',
      'America/St_Johns',
      'Europe/London',
      'Europe/Dublin',
      'Australia/Perth',
      'Australia/Darwin',
      'Australia/Adelaide',
      'Australia/Sydney',
      'Australia/Melbourne',
      'Australia/Brisbane',
      'Pacific/Auckland',
      'Pacific/Honolulu',
    ];
    const sortedTimezonesIds = [
      ...topTimezonesIds.filter((t) => allTimezonesIds.includes(t)),
      ...allTimezonesIds.filter((t) => !topTimezonesIds.includes(t)),
    ];
    return sortedTimezonesIds.map((id) => {
      const tz = getTimezoneInfo(id);
      return {
        value: tz.id,
        label: tz.displayName,
      };
    });
  }, []);

  return <RMSearchSelect options={options} label={label} value={value} error={error} onChange={onChange} />;
}
