import { createStore, StoreApi, useStore } from 'zustand';

interface DefaultState {
  type: 'default';
}

interface EditProjectState {
  type: 'edit-project';
  projectId: string;
}

interface EditSubjectState {
  type: 'edit-subject';
  projectId: string;
}

interface EditPerspectiveState {
  type: 'edit-perspective';
  projectId: string;
}

interface EditPromptSettingsState {
  type: 'edit-prompt-settings';
  projectId: string;
}

interface EditProfileState {
  type: 'edit-profile';
  personId: string;
}

interface EditNotificationSettingsState {
  type: 'edit-notification-settings';
  personId: string;
  projectId: string;
}

export type ProjectSettingsDialogPanelState =
  | DefaultState
  | EditProjectState
  | EditSubjectState
  | EditPerspectiveState
  | EditPromptSettingsState
  | EditProfileState
  | EditNotificationSettingsState;
export type ProjectSettingsDialogPanelStore = StoreApi<ProjectSettingsDialogPanelState>;

export function createProjectSettingsDialogPanelStore(): ProjectSettingsDialogPanelStore {
  return createStore<ProjectSettingsDialogPanelState>(() => ({ type: 'default' }));
}

export function openEditProjectDialog(store: ProjectSettingsDialogPanelStore, projectId: string): void {
  store.setState(
    {
      type: 'edit-project',
      projectId,
    },
    true,
  );
}

export function openEditSubjectDialog(store: ProjectSettingsDialogPanelStore, projectId: string): void {
  store.setState(
    {
      type: 'edit-subject',
      projectId,
    },
    true,
  );
}

export function openEditPerspectiveDialog(store: ProjectSettingsDialogPanelStore, projectId: string): void {
  store.setState(
    {
      type: 'edit-perspective',
      projectId,
    },
    true,
  );
}

export function openEditPromptSettingsDialog(store: ProjectSettingsDialogPanelStore, projectId: string): void {
  store.setState(
    {
      type: 'edit-prompt-settings',
      projectId,
    },
    true,
  );
}

export function openEditProfileDialog(store: ProjectSettingsDialogPanelStore, personId: string): void {
  store.setState(
    {
      type: 'edit-profile',
      personId,
    },
    true,
  );
}

export function openEditNotificationsSettingsDialog(
  store: ProjectSettingsDialogPanelStore,
  projectId: string,
  personId: string,
): void {
  store.setState(
    {
      type: 'edit-notification-settings',
      projectId,
      personId,
    },
    true,
  );
}

export function closeProjectSettingsDialog(store: ProjectSettingsDialogPanelStore): void {
  store.setState(
    {
      type: 'default',
    },
    true,
  );
}

export function useProjectSettingsDialogPanelState(
  store: ProjectSettingsDialogPanelStore,
): ProjectSettingsDialogPanelState {
  return useStore(store);
}
