import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { RMPage } from '@/components/RMPage';
import { useAutohideIntercomBubble } from '@/hooks/useAutohideIntercomBubble';
import { AppLayout } from '@/layouts/AppLayout';
import { MobilePageHeaderContainer } from '@/modules/navbar/containers/MobilePageHeaderContainer';
import { setNavbarProjectId, useNavbarProjectId, useNavbarStore } from '@/modules/navbar/states';
import { ProjectBannerContainer } from '@/modules/project/containers/ProjectBanner.container';
import { RementoPage } from '@/modules/routing';
import { ProjectShareButtonContainer } from '@/modules/sharing/containers/ProjectShareButton.container';
import { ProjectShareHeaderContainer } from '@/modules/sharing/containers/ProjectShareHeader.container.tsx';
import { useServices } from '@/Services';

import { StoriesContainer } from '../../modules/stories/containers/Stories.container';

function Stories() {
  // Services
  const { storyViewerAnalyticsService } = useServices();
  useAutohideIntercomBubble();

  // State
  const navbarStore = useNavbarStore();
  const projectId = useNavbarProjectId(navbarStore);

  useEffect(() => {
    storyViewerAnalyticsService.onStoriesArrived();
  }, [storyViewerAnalyticsService]);

  return (
    <RMPage.Root>
      <MobilePageHeaderContainer>
        <ProjectShareButtonContainer page="stories" />
      </MobilePageHeaderContainer>
      <ProjectBannerContainer projectId={projectId ?? ''} />
      <RMPage.Header>
        <RMPage.Title title="Stories" />
        <RMPage.HeaderActions>
          <ProjectShareHeaderContainer projectId={projectId} page="stories" />
        </RMPage.HeaderActions>
      </RMPage.Header>
      <RMPage.Content>{projectId && <StoriesContainer projectId={projectId} visualization="grid" />}</RMPage.Content>
    </RMPage.Root>
  );
}

export function StoriesPage() {
  const params = useParams();
  const navbarStore = useNavbarStore();

  useEffect(() => {
    setNavbarProjectId(navbarStore, params.projectId ?? '');
  }, [navbarStore, params.projectId]);

  return (
    <RementoPage type="default">
      <AppLayout>
        <Stories />
      </AppLayout>
    </RementoPage>
  );
}
