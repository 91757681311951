import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { faChevronLeft, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { EntityType } from '@remento/types/entity';
import { ConflictError, ConflictErrorType, NotFoundError } from '@remento/types/error';

import CollaboratorInviteSrc from '@/assets/collaborator-invite.png';
import WordmarkSrc from '@/assets/wordmark.svg';
import WordmarkPrimarySrc from '@/assets/wordmark-primary.svg';
import { RMBackButton } from '@/components/RMBackButton';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMInput } from '@/components/RMInput';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { InputContainer } from '@/modules/form/container';
import { submitForm } from '@/modules/form/form';
import { getProjectPromptsPath, getSetupPerspectivePath } from '@/modules/routing';
import { useServices } from '@/Services';
import { useProjectInviteLink } from '@/services/api/project';
import { captureException } from '@/utils/captureException';
import { writeToClipboard } from '@/utils/clipboard';
import { secureUuid } from '@/utils/uuid';

import {
  createEmailForm,
  IdentifiableEmailForm,
  useAreAllFormsValid,
  useIncludesAtLeastOneEmail,
} from './SetupCollaboratorsPage.hooks';
import {
  BackButton,
  Body,
  Buttons,
  EmailFormsWrapper,
  Header,
  HeaderLogo,
  InputButtonGroup,
  InputButtonGroupButton,
  InputButtonGroupInput,
  LeftPanel,
  MobileFooter,
  MobileHeader,
  RightPanel,
  RightPanelImage,
  Separator,
  SeparatorLine,
  StyledPage,
} from './SetupCollaboratorsPage.styles';

export function SetupCollaboratorsPage() {
  const params = useParams();
  const projectId = params.projectId ?? '';

  // Services
  const { aclCacheService, projectCacheService, collaborationAnalyticsService } = useServices();
  const navigate = useNavigate();
  const isMobile = useIsMobileViewport();

  // Queries
  const inviteLinkQuery = useProjectInviteLink(projectId);

  // State
  const [emailForms, setEmailForms] = useState<IdentifiableEmailForm[]>(() => {
    return Array.from({ length: 3 }).map(() => ({ id: secureUuid(), form: createEmailForm() }));
  });
  const areAllFormsValid = useAreAllFormsValid(emailForms);
  const includesAtLeastOneEmail = useIncludesAtLeastOneEmail(emailForms);

  // Callbacks
  const handleAddAnother = useCallback(() => {
    setEmailForms((currentEmailForms) => currentEmailForms.concat({ id: secureUuid(), form: createEmailForm() }));
  }, []);

  const handleFinish = useCallback(async () => {
    try {
      const project = await projectCacheService.getProject(projectId);
      if (project == null) {
        throw new NotFoundError('project-not-found');
      }

      const projectAclGroup = await aclCacheService.getPrimaryAclGroup(project.acl, EntityType.PROJECT);
      if (projectAclGroup == null) {
        throw new NotFoundError('primary-acl-group-not-found');
      }

      const emails = new Set<string>();
      await Promise.all(
        emailForms.map(async (emailForm) => {
          await submitForm(emailForm.form, ({ email }) => {
            if (email.trim().length > 0) {
              emails.add(email);
            }
          });
        }),
      );

      if (emails.size > 0) {
        collaborationAnalyticsService.onInvitedViaEmail(emails.size, 'onboarding');
        await aclCacheService.sendEmailInvite(projectAclGroup.id, Array.from(emails));
      }
      navigate(getProjectPromptsPath(projectId));
    } catch (error) {
      if (error instanceof ConflictError && error.data?.type === ConflictErrorType.EMAIL_ALREADY_PART_OF_ACL_GROUP) {
        toast('This email is already associated with a collaborator on the account.', 'root-toast', 'error');
        return;
      }
      toast('An unexpected error has occurred.', 'root-toast', 'error');
      captureException(error, true);
    }
  }, [aclCacheService, collaborationAnalyticsService, emailForms, navigate, projectCacheService, projectId]);

  const handleCopyInviteLink = useCallback(async () => {
    try {
      collaborationAnalyticsService.onInvitedViaLink('onboarding');
      await writeToClipboard(inviteLinkQuery.data ?? '');
      toast('Link copied to the clipboard');
    } catch (error) {
      toast('An unexpected error has occurred.', 'root-toast', 'error');
      captureException(error, true);
    }
  }, [collaborationAnalyticsService, inviteLinkQuery.data]);

  const handleSkip = useCallback(() => {
    navigate(getProjectPromptsPath(projectId));
  }, [navigate, projectId]);

  const handleBack = useCallback(() => {
    navigate(getSetupPerspectivePath(projectId));
  }, [navigate, projectId]);

  const buttons = (
    <Buttons>
      <RMButton fullWidth onClick={handleSkip}>
        Skip for now
      </RMButton>
      <RMButton
        background="primary"
        fullWidth
        autoLoading
        disabled={includesAtLeastOneEmail == false || areAllFormsValid == false}
        onClick={handleFinish}
      >
        Send invites
      </RMButton>
    </Buttons>
  );

  return (
    <StyledPage>
      {isMobile ? (
        <MobileHeader>
          <BackButton icon={faChevronLeft} size="xl" color="var(--inverse-on-surface-secondary)" onClick={handleBack} />
          <HeaderLogo src={WordmarkSrc} alt="Remento wordmark" />
        </MobileHeader>
      ) : (
        <Header>
          <HeaderLogo src={WordmarkPrimarySrc} alt="Remento wordmark" />
        </Header>
      )}

      <Body>
        <LeftPanel>
          {isMobile == false && (
            <>
              <RMBackButton onClick={handleBack} />
              <RMSpacer direction="column" spacing="2xl" />
            </>
          )}
          <RMText size={isMobile ? 'l' : 'xl'} type="serif" color="on-surface-primary">
            Invite collaborators
          </RMText>
          <RMSpacer direction="column" spacing={isMobile ? 'sm' : 'xl'} />
          <RMText size="s" type="sans" color="on-surface-primary" lineHeight="s">
            By adding family and friends to this book, they can receive stories as they're recorded. They can also send
            reactions and comments directly to the Storyteller.
          </RMText>

          <RMSpacer direction="column" spacing="xl" />

          {/* Invite link mobile*/}
          {isMobile && (
            <>
              <RMButton fullWidth autoLoading onClick={handleCopyInviteLink}>
                Copy private invite link
              </RMButton>
            </>
          )}

          {/* Invite link desktop*/}
          {isMobile == false && (
            <>
              <RMText type="sans" size="xs" color="on-surface-secondary" bold>
                Invite via private link
              </RMText>
              <RMSpacer direction="column" spacing="xs" />
              <InputButtonGroup>
                <InputButtonGroupInput id="project-invite-link" value={inviteLinkQuery.data ?? ''} readOnly />
                <InputButtonGroupButton autoLoading onClick={handleCopyInviteLink}>
                  Copy
                </InputButtonGroupButton>
              </InputButtonGroup>
            </>
          )}

          {/* Separator */}
          <Separator>
            <SeparatorLine />
            <RMText type="sans" size="xs" color="on-surface-primary">
              Or
            </RMText>
            <SeparatorLine />
          </Separator>

          {/* Invite via email */}
          <EmailFormsWrapper>
            <RMText type="sans" size="xs" color="on-surface-secondary" bold>
              Invite with email
            </RMText>

            {emailForms.map((emailForm) => (
              <InputContainer key={emailForm.id} form={emailForm.form} path="email">
                {(props) => <RMInput id={`${emailForm.id}-email-input`} placeholder="firstlast@email.com" {...props} />}
              </InputContainer>
            ))}

            <RMButton background="transparent" size="small" leftIcon={faPlus} onClick={handleAddAnother}>
              Add another
            </RMButton>
          </EmailFormsWrapper>

          {/* Buttons */}
          {isMobile == false && (
            <>
              <RMSpacer direction="column" spacing="2xl" />
              {buttons}
            </>
          )}
        </LeftPanel>

        {isMobile == false && (
          <RightPanel>
            <RightPanelImage src={CollaboratorInviteSrc} />
          </RightPanel>
        )}
      </Body>

      {isMobile && <MobileFooter>{buttons}</MobileFooter>}
    </StyledPage>
  );
}
