import { Fragment } from 'react';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMDateInput } from '@/components/RMDateInput/RMDateInput';
import { RMDialogPanel } from '@/components/RMDialogPanel';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { RMToggle } from '@/components/RMToggle/RMToggle';
import { RMWeekdaySelector } from '@/components/RMWeekdaySelector/RMWeekdaySelector';
import { InputController } from '@/modules/form/container';
import { useIsFormValid } from '@/modules/form/form';
import { setInputValue, useInputValue } from '@/modules/form/input';
import { genitiveCase } from '@/utils/genitiveCase';

import { PromptSettingsForm } from '../../forms/prompt-settings-form';
import { TextChunk } from '../../hooks/usePromptFrequencyText';

import { ContentWrapper, Separator, ToggleWrapper } from './PromptSettingsEdit.styles';

export interface PromptSettingsEditProps {
  form: PromptSettingsForm;
  storytellerFirstName: string | null;
  isCurrentUserTheStoryteller: boolean;
  promptFrequencyText: TextChunk[];
  onSubmit: () => void;
  onClose: () => void;
}

export function PromptSettingsEdit({
  form,
  storytellerFirstName,
  isCurrentUserTheStoryteller,
  promptFrequencyText,
  onSubmit,
  onClose,
}: PromptSettingsEditProps) {
  const isReceivingPrompts = useInputValue(form, 'isReceivingPrompts');
  const isFormValid = useIsFormValid(form);

  return (
    <RMDialogPanel.Root open onClose={onClose}>
      <RMDialogPanel.Title onClose={onClose}>Prompt settings</RMDialogPanel.Title>
      <RMDialogPanel.Content>
        <ContentWrapper>
          <RMText type="sans" bold trackedOutCaps size="xs" color="on-surface-primary">
            PAUSE PROMPTS
          </RMText>
          <RMSpacer spacing="md" direction="column" />
          <ToggleWrapper>
            <RMText type="sans" size="s" color="on-surface-primary">
              Receive prompts from Remento
            </RMText>
            <InputController form={form} path="isReceivingPrompts">
              {(props) => (
                <RMToggle
                  label={props.value ? 'ON' : 'OFF'}
                  checked={props.value ?? false}
                  onChange={(newValue) => {
                    props.onChange(newValue);
                    props.onBlur();
                  }}
                />
              )}
            </InputController>
          </ToggleWrapper>

          <Separator />

          <RMText type="sans" bold trackedOutCaps size="xs" color="on-surface-primary">
            SELECT DAY & FREQUENCY
          </RMText>
          <RMSpacer spacing="md" direction="column" />
          <RMText type="sans" size="s" color="on-surface-primary">
            Select the day of the week for {isCurrentUserTheStoryteller ? 'you' : storytellerFirstName} to receive
            prompts. To send prompts more than once a week, select each day you would like a prompt sent.
          </RMText>
          <RMSpacer spacing="md" direction="column" />
          <InputController form={form} path="weekdays">
            {(props) => (
              <RMWeekdaySelector
                softDisabled={!isReceivingPrompts}
                size="small"
                value={isReceivingPrompts ? props.value ?? null : null}
                onChange={(newValue) => {
                  if (!isReceivingPrompts) {
                    // Unpause the prompts if the user selects a new day
                    setInputValue(form, 'isReceivingPrompts', true);
                  }

                  props.onChange(newValue);
                  props.onBlur();
                }}
              />
            )}
          </InputController>
          <RMSpacer spacing="2xl" direction="column" />

          <RMText type="sans" bold trackedOutCaps size="xs" color="on-surface-primary">
            SELECT DELIVERY TIME
          </RMText>
          <RMSpacer spacing="md" direction="column" />
          <InputController form={form} path="time" immediate>
            {(props) => <RMDateInput id="prompt-time" type="time" disabled={!isReceivingPrompts} {...props} />}
          </InputController>
          <RMSpacer spacing="2xl" direction="column" />
          <RMText type="sans" size="s" color="on-surface-primary">
            {isReceivingPrompts ? (
              <>
                {promptFrequencyText.map((text) =>
                  text.bold ? <b key={text.id}>{text.value}</b> : <Fragment key={text.id}>{text.value}</Fragment>,
                )}
              </>
            ) : (
              `${
                isCurrentUserTheStoryteller ? 'Your' : genitiveCase(storytellerFirstName ?? undefined)
              } prompts are paused.`
            )}
          </RMText>
        </ContentWrapper>
      </RMDialogPanel.Content>
      <RMDialogPanel.Actions>
        <RMButton background="primary" size="large" fullWidth onClick={onSubmit} autoLoading disabled={!isFormValid}>
          Save
        </RMButton>
      </RMDialogPanel.Actions>
    </RMDialogPanel.Root>
  );
}
