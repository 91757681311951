import { AclGroupInvitee, PublicAclGroup, PublicAclGroupMember } from './acl';
import { AssetAlternativeView } from './alternative';
import { Asset } from './asset';
import { BookTemplate } from './book';
import { BookOrderView } from './book-order';
import { Person } from './person';
import { Poll } from './poll';
import { PollVote } from './poll-vote';
import { Project, Prompt, Question } from './project';
import { Reaction, ReactionSuggestion } from './reaction';
import { Recording } from './recording';
import { Story } from './story';
import { User, UserPurchase } from './user';
import { UserNotificationSettings } from './user-notification-settings';

export enum EntityType {
  PERSON = 'person',
  USER = 'user',
  USER_NOTIFICATION_SETTINGS = 'user-notification-settings',
  PROJECT = 'series',
  PROMPT = 'prompt',
  QUESTION = 'question',
  ASSET = 'asset',
  ASSET_ALTERNATIVE = 'asset-alternative',
  BOOK_TEMPLATE = 'book',
  BOOK_ORDER = 'book-order',
  STORY = 'story',
  REACTION = 'reaction',
  REACTION_SUGGESTION = 'reaction-suggestion',
  RECORDING = 'recording',
  ACL_GROUP = 'acl-group',
  ACL_GROUP_MEMBER = 'acl-group-member',
  ACL_GROUP_INVITEE = 'acl-group-invitee',
  POLL = 'poll',
  POLL_VOTE = 'poll-vote',
  USER_PURCHASE = 'user-purchase',
}

export type EntityTypeMapping = {
  [EntityType.PERSON]: Person;
  [EntityType.USER]: User;
  [EntityType.PROJECT]: Project;
  [EntityType.PROMPT]: Prompt;
  [EntityType.QUESTION]: Question;
  [EntityType.ASSET]: Asset;
  [EntityType.ASSET_ALTERNATIVE]: AssetAlternativeView;
  [EntityType.BOOK_TEMPLATE]: BookTemplate;
  [EntityType.BOOK_ORDER]: BookOrderView;
  [EntityType.STORY]: Story;
  [EntityType.REACTION]: Reaction;
  [EntityType.REACTION_SUGGESTION]: ReactionSuggestion;
  [EntityType.RECORDING]: Recording;
  [EntityType.ACL_GROUP]: PublicAclGroup;
  [EntityType.ACL_GROUP_MEMBER]: PublicAclGroupMember;
  [EntityType.ACL_GROUP_INVITEE]: AclGroupInvitee;
  [EntityType.USER_NOTIFICATION_SETTINGS]: UserNotificationSettings;
  [EntityType.POLL]: Poll;
  [EntityType.POLL_VOTE]: PollVote;
  [EntityType.USER_PURCHASE]: UserPurchase;
};

export type EntityResponseMap = {
  [entityType in keyof EntityTypeMapping]?: Array<EntityTypeMapping[entityType]>;
};

export interface CachedCollection {
  type: EntityType;
  id: Record<string, string>;
  itemIds: string[];
}

// TODO-CACHING-REFACTOR - Remove this the old type after refactoring all endpoints
export type EntityResponse =
  | EntityResponseMap
  | {
      entities: EntityResponseMap;
      collections?: Array<CachedCollection>;
    };
