import { useCallback } from 'react';

import { EmbeddedTypeform, TypeformSubmitEvent } from '@/components/EmbeddedTypeform/EmbeddedTypeform';
import { toast } from '@/components/RMToast/RMToast';
import { RementoPage } from '@/modules/routing';
import { useServices } from '@/Services';
import { captureException } from '@/utils/captureException';
import { getMarketingCookies } from '@/utils/marketing-cookies';

function InternalCheckoutGiftPage() {
  const { checkoutService } = useServices();

  const handleSubmit = useCallback(
    async (e: TypeformSubmitEvent) => {
      try {
        const marketingCookies = getMarketingCookies();
        const checkout = await checkoutService.giftCheckout({
          ...marketingCookies,
          typeformFormId: e.formId,
          typeformResponseId: e.responseId,
        });
        window.open(checkout.paymentUrl, '_self');
      } catch (error) {
        toast('An unexpected error has occurred.', 'root-toast', 'error');
        captureException(error, true);
      }
    },
    [checkoutService],
  );

  return <EmbeddedTypeform formId={import.meta.env.VITE_GIFT_FORM_ID} fullScreen onSubmit={handleSubmit} />;
}

export function CheckoutGiftPage() {
  return (
    <RementoPage type="empty">
      <InternalCheckoutGiftPage />
    </RementoPage>
  );
}
