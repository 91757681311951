import { Observer, Select, StateStore, StateType, Unsubscribe } from './@types';

export * from './@types';
export type Comparator<S> = (s1: S, s2: S) => boolean;
export type Transform<S> = (s1: S) => S;

export function equalityCompare<S>(s1: S, s2: S) {
  return s1 === s2;
}

export function shallowCompare<S>(a: S, b: S) {
  if (a === b) return true;
  if (!(a instanceof Object) || !(b instanceof Object)) return false;

  const keys = Object.keys(a);
  const length = keys.length;

  for (let i = 0; i < length; i++) {
    if (!(keys[i] in b)) return false;
  }
  for (let i = 0; i < length; i++) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (a[keys[i]] !== b[keys[i]]) return false;
  }
  return length === Object.keys(b).length;
}

export function create<S extends StateType>(initial: S): StateStore<S> {
  return {
    state: initial,
    subscribers: new Set(),
    delayedSubscribers: new Set(),
    pendingUpdate: false,
  };
}

export function setState<S extends StateType>(store: StateStore<S>, next: S | Transform<S>) {
  const prevState = store.state;
  const state = typeof next === 'function' ? next(prevState) : next;
  if (prevState === state) {
    return;
  }
  // Update the state
  store.state = state;
  // Trigger the subscribers
  store.subscribers.forEach((c) => c(state, prevState));
  // Setup a timeout to trigger subscribers on the next tick if they are not already scheduled
  if (store.pendingUpdate) return;
  store.pendingUpdate = true;
  setTimeout(() => {
    // Keep a copy of the state in case it's updated by one of the subscribers
    const currentState = store.state;
    // Mark the update as done so that any updates by the subscribers will schedule triggering the subscribers on
    // the next tick
    store.pendingUpdate = false;
    store.delayedSubscribers.forEach((c) => c(currentState, prevState));
  }, 0);
}

export function getState<S extends StateType>(store: StateStore<S>): S {
  return store.state;
}

export function subscribe<S extends StateType>(
  store: StateStore<S>,
  observer: Observer<S>,
  delayed = false,
): Unsubscribe {
  const subscribers = delayed ? store.delayedSubscribers : store.subscribers;
  subscribers.add(observer);
  return () => subscribers.delete(observer);
}

export function selector<S, SS>(
  select: Select<S, SS>,
  observer: Observer<SS>,
  equality: Comparator<SS> = equalityCompare,
): Observer<S> {
  return (state, prevState) => {
    const sState = select(state);
    const sPrevState = select(prevState);
    if (!equality(sState, sPrevState)) observer(sState, sPrevState);
  };
}

export function subscribeSelector<S extends StateType, SS>(
  store: StateStore<S>,
  observer: Observer<SS>,
  select: Select<S, SS>,
  delayed = false,
  equality: Comparator<SS> = equalityCompare,
): Unsubscribe {
  return subscribe(store, selector(select, observer, equality), delayed);
}
