import { styled } from '@linaria/react';

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;

  body[data-mobile='true'] & {
    padding: 0 var(--spacing-lg);
  }
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 400px;
`;

export const Separator = styled.div`
  height: 1px;
  background-color: var(--border-hairline);
  margin: var(--spacing-md) 0;
`;
