import { BaseMutation } from '@remento/types/base-entity';
import {
  ConflictError,
  ConflictErrorType,
  deserializeRementoError,
  isSerializableRementoError,
  NetworkError,
  UnknownError,
} from '@remento/types/error';
import axios, { AxiosRequestConfig, isAxiosError } from 'axios';

import { logger } from '@/logger';

import { EntityMutation } from './cache';

interface RetryConfig extends AxiosRequestConfig {
  retry?: number;
}

export const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  adapter: 'fetch',
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Validate if the request should be retried.
    // Right now we are only retrying vclock mismatching errors.
    if (!isAxiosError(error)) {
      throw error;
    }
    if (!error.config || !error.response?.data) {
      throw error;
    }
    if (!isSerializableRementoError(error.response.data)) {
      throw error;
    }
    const rmError = deserializeRementoError(error.response.data);
    if (
      rmError instanceof ConflictError === false ||
      rmError.data?.type !== ConflictErrorType.VCLOCK_MISMATCH ||
      rmError.data.updatedEntities === null
    ) {
      throw error;
    }

    // Retry up to 3 times.
    const config = error.config as RetryConfig;
    if (config.retry === undefined) {
      config.retry = 0;
    }
    if (config.retry >= 3) {
      throw error;
    }
    config.retry += 1;

    const timeout = 250 * Math.pow(1.2, config.retry);
    const delayRetryRequest = new Promise<void>((resolve) => {
      setTimeout(() => {
        console.log('Retrying mutations');
        resolve();
      }, timeout);
    });

    //Update the each mutation with the new vclock and version.
    const updatedEntitiesResponse = rmError.data.updatedEntities;
    // TODO - We can remove this after refactoring all endpoints to the new interface
    const updatedEntities =
      'entities' in updatedEntitiesResponse ? updatedEntitiesResponse.entities : updatedEntitiesResponse;
    const body = JSON.parse(config.data) as EntityMutation<BaseMutation<string, unknown>>[];
    const updatedBody = body.map((entityMutation) => {
      const entities = updatedEntities[entityMutation.type];
      if (!entities) {
        throw new Error(`Missing updated entity for type ${entityMutation.type} and id ${entityMutation.id}`);
      }
      const entity = entities.find((e) => e.id === entityMutation.id);
      if (!entity) {
        throw new Error(`Missing updated entity for type ${entityMutation.type} and id ${entityMutation.id}`);
      }

      if (!('vclock' in entity && 'version' in entity)) {
        throw new Error(`Entity ${entityMutation.type}:${entityMutation.id} does not have a vclock`);
      }

      return {
        ...entityMutation,
        mutations: entityMutation.mutations.map((m) => ({
          ...m,
          vclock: entity.vclock,
          version: entity.version,
        })),
      };
    });

    await delayRetryRequest;
    return api({ ...config, data: updatedBody });
  },
);

// Send the errors to datadog
api.interceptors.response.use(
  (response) => response,
  (error) => {
    let formattedError = error;
    if (isAxiosError(error)) {
      formattedError = error.toJSON();

      // Hide the auth token from the log
      if (formattedError.config?.headers?.Authorization) {
        formattedError.config.headers.Authorization = '***';
      }
    }

    if (isAxiosError(error) && isSerializableRementoError(error.response?.data)) {
      logger.warn('REQUEST_ERROR', {
        error: formattedError,
      });
    } else {
      // Do not log aborted requests
      if (isAxiosError(error) === false || error.code !== 'ERR_CANCELED') {
        logger.error('REQUEST_ERROR', {
          error: formattedError,
        });
      }
    }

    return Promise.reject(error);
  },
);

// Transform the error into a RementoError
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (isAxiosError(error)) {
      if (isSerializableRementoError(error.response?.data)) {
        throw deserializeRementoError(error.response.data);
      }

      if (error.code === 'ERR_NETWORK') {
        throw new NetworkError('Network error', error);
      }
    }

    throw new UnknownError(String(error), error instanceof Error ? error : null);
  },
);
