import { EntityType } from '@remento/types/entity';
import { User } from '@remento/types/user';
import { UserNotificationSettings } from '@remento/types/user-notification-settings';
import { QueryClient } from '@tanstack/react-query';

import { EntityCacheManagerService } from '../cache/entity-cache-manager.types';

import { UserCacheService, UserService } from './user.types';

export class DefaultUserCacheService implements UserCacheService {
  constructor(
    private remoteService: UserService,
    private entityCacheManagerService: EntityCacheManagerService,
    private queryClient: QueryClient,
  ) {}

  getUserByPersonId(personId: string): Promise<User | null> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildEntityQuery(
        EntityType.USER,
        personId,
        () => this.remoteService.getUserByPersonId(personId),
        (id: string, user: User) => id === user.personId,
      ),
    );
  }

  getNotificationSettingsByUserId(userId: string): Promise<UserNotificationSettings | null> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildEntityQuery(
        EntityType.USER_NOTIFICATION_SETTINGS,
        userId,
        () => this.remoteService.getNotificationSettingsByUserId(userId),
        (id: string, settings: UserNotificationSettings) => id === settings.userId,
      ),
    );
  }
  async loadUserStorytellers(): Promise<void> {
    const response = await this.remoteService.getUserStorytellers();
    await this.entityCacheManagerService.cacheResponse(response);
  }
}
