import { ReactNode } from 'react';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';

import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { RMText } from '../RMText/RMText';

import { Content, ContentWrapper, Divider, Header, Icon, Image, Wordmark } from './RMErrorRoot.styles';

export interface RMErrorRootProps {
  icon: IconProp | string | null;
  iconSize?: SizeProp | string;
  title: string;
  Action?: ReactNode;
  Message?: ReactNode;
  Footer?: ReactNode;
}

export function RMErrorRoot({ icon, iconSize, title, Message, Action, Footer }: RMErrorRootProps) {
  const mobile = useIsMobileViewport();

  return (
    <>
      {!mobile && (
        <Header>
          <Wordmark />
        </Header>
      )}
      <Divider />
      <ContentWrapper>
        <Content>
          {icon != null && typeof icon === 'string' && (
            <Image src={icon} style={{ width: iconSize?.endsWith('rem') ? iconSize : '5rem' }} alt="Error icon" />
          )}
          {icon != null && typeof icon === 'object' && (
            <Icon icon={icon} size={(iconSize as SizeProp) ?? '5x'} color={'var(--primary-decorative)'} />
          )}
          <RMText align="center" size="l" type="serif">
            {title}
          </RMText>
          {Message}
          {Action}
          {Footer}
        </Content>
      </ContentWrapper>
    </>
  );
}
