import { useMemo } from 'react';

import { RMText } from '../RMText/RMText';

import { Container, Toggle } from './RMToggle.styles';

export interface RMToggleProps {
  label?: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
}

export function RMToggle({ label, checked, disabled = false, onChange }: RMToggleProps) {
  const id = useMemo(() => (Math.random() * 100).toString(), []);

  return (
    <Container>
      <Toggle disabled={disabled}>
        <input
          type="checkbox"
          id={id}
          checked={checked}
          disabled={disabled}
          onChange={(event) => onChange(event.target.checked)}
        />
        <label htmlFor={id} />
      </Toggle>

      {label && (
        <label htmlFor={id}>
          <RMText type="sans" size="s" bold color="on-surface-primary">
            {label}
          </RMText>
        </label>
      )}
    </Container>
  );
}
