import { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { faClose, faSparkles } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StoryLengthType, StoryPerspectiveType } from '@remento/types/story';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { RMInputRange } from '@/components/RMInputRange/RMInputRange';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { InputController } from '@/modules/form/container';
import { setInputValue } from '@/modules/form/input';

import { StoryRegenerateForm } from '../../forms/story-regenerate.form';

import {
  Body,
  FixedFormItem,
  Footer,
  FormItem,
  FormItemLabel,
  FormWrapper,
  Header,
  HeaderButtons,
  HeaderTitle,
  InputRangeWrapper,
  LengthDescribe,
  PerspectiveButton,
  PerspectiveOptions,
  ScrimOverlay,
  StyledStoryRegenerate,
} from './StoryRegenerate.styles';

interface StoryRegenerateProps {
  form: StoryRegenerateForm;
  storytellerName: string;
  hideLength: boolean;
  onOpenFaq: () => void;
  onGeneratePreview: () => void;
  onRegenerateCancel: () => void;
}

interface PerspectiveItem {
  label: string;
  value: StoryPerspectiveType;
}

export function StoryRegenerate({
  form,
  storytellerName,
  hideLength,
  onOpenFaq,
  onGeneratePreview,
  onRegenerateCancel,
}: StoryRegenerateProps) {
  const isMobile = useIsMobileViewport();

  const perspectives: PerspectiveItem[] = [
    { label: 'First person', value: StoryPerspectiveType.FIRST_PERSON },
    { label: 'Third person', value: StoryPerspectiveType.THIRD_PERSON },
    { label: 'Cleaned transcript', value: StoryPerspectiveType.TRANSCRIPT },
  ];

  const examples: Record<StoryPerspectiveType, ReactNode> = {
    [StoryPerspectiveType.FIRST_PERSON]: (
      <>
        Example: <i>I went to store...</i>
      </>
    ),
    [StoryPerspectiveType.THIRD_PERSON]: (
      <>
        Example: <i>{storytellerName} went to store...</i>
      </>
    ),
    [StoryPerspectiveType.TRANSCRIPT]: 'The original transcription, with words like "um" and "ah" removed.',
  };

  const summaryLength = [
    StoryLengthType.VERY_SHORT,
    StoryLengthType.SHORT,
    StoryLengthType.MEDIUM,
    StoryLengthType.LONG,
    StoryLengthType.VERY_LONG,
  ];

  const component = (
    <>
      <ScrimOverlay
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.15 }}
        onClick={onRegenerateCancel}
      />
      <StyledStoryRegenerate
        initial={{ translateY: '100%' }}
        animate={{ translateY: '0%', transition: { duration: 0.25, ease: 'easeOut' } }}
        exit={{ translateY: '100%', transition: { duration: 0.25, ease: 'easeIn' } }}
      >
        <Body>
          <Header>
            <HeaderTitle>
              <FontAwesomeIcon icon={faSparkles} color="var(--on-surface-primary)" size="lg" />
              <RMText type="sans" size="m" color="on-surface-primary" bold>
                Writing assistant
              </RMText>
            </HeaderTitle>
            <RMText type="sans" size="xs" color="on-surface-primary">
              Adjust the settings below to create a new version of the story.
            </RMText>
            <HeaderButtons>
              <RMIconButton
                tooltip={{ label: 'Open FAQ', position: 'bottom' }}
                icon={faQuestionCircle}
                backgroundColor="transparent"
                onClick={onOpenFaq}
              />
              <RMIconButton
                tooltip={{ label: 'Close', position: 'bottom' }}
                icon={faClose}
                backgroundColor="transparent"
                onClick={onRegenerateCancel}
              />
            </HeaderButtons>
          </Header>

          <FormWrapper>
            <FixedFormItem>
              <FormItemLabel type="sans" size="xxs" bold color="on-surface-tertiary">
                STORY PERSPECTIVE
              </FormItemLabel>
              <InputController form={form} path="perspective">
                {(props) => (
                  <>
                    <PerspectiveOptions>
                      {perspectives.map(({ value, label }) => (
                        <PerspectiveButton
                          key={value}
                          background="outlined"
                          data-active={props.value === value}
                          onClick={() => {
                            setInputValue(form, 'perspective', value);
                          }}
                        >
                          {label}
                        </PerspectiveButton>
                      ))}
                    </PerspectiveOptions>
                    {props.value && (
                      <RMText type="sans" size="xs" color="on-surface-primary">
                        {examples[props.value]}
                      </RMText>
                    )}
                  </>
                )}
              </InputController>
            </FixedFormItem>

            <FormItem data-hide={hideLength}>
              <FormItemLabel type="sans" size="xxs" bold color="on-surface-tertiary">
                LENGTH
              </FormItemLabel>
              <InputRangeWrapper>
                <InputController form={form} path="length">
                  {(props) => (
                    <RMInputRange
                      {...props}
                      max={4}
                      min={0}
                      step={1}
                      onChange={(event) => props.onChange(summaryLength[Number(event.currentTarget.value)])}
                      value={summaryLength.indexOf(props.value ?? StoryLengthType.MEDIUM)}
                    />
                  )}
                </InputController>
              </InputRangeWrapper>
              <LengthDescribe>
                <RMText type="sans" size="xs" color="on-surface-primary">
                  Shorter
                </RMText>
                <RMText type="sans" size="xs" color="on-surface-primary">
                  Longer
                </RMText>
              </LengthDescribe>
            </FormItem>
          </FormWrapper>
        </Body>

        <Footer>
          <RMButton
            onClick={() => {
              onRegenerateCancel();
            }}
          >
            Cancel
          </RMButton>

          <RMButton
            background="primary"
            onClick={() => {
              onGeneratePreview();
            }}
          >
            Generate preview
          </RMButton>
        </Footer>
      </StyledStoryRegenerate>
    </>
  );

  if (isMobile) {
    const portalRoot = document.getElementById('dialog');
    if (!portalRoot) {
      throw new Error('missing-portal-dialog-root');
    }

    return createPortal(component, portalRoot);
  }

  return component;
}
