import { Weekday } from '@/utils/tz';

import { RMText } from '../RMText/RMText';

import { StyledWeekdaySelector, WeekdaySelectorItem } from './RMWeekdaySelector.styles';

export interface RMWeekdaySelectorProps {
  value: Set<Weekday> | null;
  size?: 'default' | 'small';
  disabled?: boolean;
  softDisabled?: boolean;
  onChange: (value: Set<Weekday>) => void;
}

const ITEMS = [
  { value: Weekday.SUNDAY, label: 'Su' },
  { value: Weekday.MONDAY, label: 'Mo' },
  { value: Weekday.TUESDAY, label: 'Tu' },
  { value: Weekday.WEDNESDAY, label: 'We' },
  { value: Weekday.THURSDAY, label: 'Th' },
  { value: Weekday.FRIDAY, label: 'Fr' },
  { value: Weekday.SATURDAY, label: 'Sa' },
];

export function RMWeekdaySelector({
  value,
  size = 'default',
  disabled,
  softDisabled,
  onChange,
}: RMWeekdaySelectorProps) {
  return (
    <StyledWeekdaySelector data-disabled={disabled} data-soft-disabled={softDisabled}>
      {ITEMS.map((item) => (
        <WeekdaySelectorItem
          key={item.value}
          data-selected={value != null && value.has(item.value)}
          data-size={size}
          onClick={() => {
            if (disabled) {
              return;
            }

            const newValue = new Set(value);
            if (newValue.has(item.value)) {
              newValue.delete(item.value);
            } else {
              newValue.add(item.value);
            }
            onChange(newValue);
          }}
        >
          <RMText type="sans" size="m" color="white" bold>
            {item.label}
          </RMText>
        </WeekdaySelectorItem>
      ))}
    </StyledWeekdaySelector>
  );
}
