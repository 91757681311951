import { AclGroupRole } from '@remento/types/acl';
import { EntityType } from '@remento/types/entity';
import {
  PRE_RENEWAL_PHASE_DURATION,
  SubscriptionRenewalType,
  SubscriptionType,
  UserOnboardingActionType,
  UserSubscriptionStatus,
} from '@remento/types/user';

import { OnboardingDialog } from '@/services/local/onboarding-dialog';
import { getCurrentTimezoneId } from '@/utils/tz';

import { PhoneCollectionOnboardingDialogContainer } from './containers/PhoneCollectionOnboardingDialog.container';
import { SubscriptionRenewOfferOnboardingDialogContainer } from './containers/SubscriptionRenewOfferOnboardingDialog.container';
import { TimezoneUpdateOnboardingDialogContainer } from './containers/TimezoneUpdateOnboardingDialog.container';

export const onboardingDialogs: OnboardingDialog[] = [
  {
    id: UserOnboardingActionType.STORY_PERSPECTIVE_SETTINGS_VIEWED,
    dialogType: 'default',
    title: '🎉 NEW: Change how Remento writes your stories',
    content: (
      <>
        You can now change the default writing style that turns your recordings into a written story.
        <br />
        <br />
        Either use our Writing Assistant to craft a written story in either the first or third person (default), or
        maintain the exact words and structure with a “cleaned transcript.”
        <br />
        <br />
        <a
          href="https://help.remento.co/en/articles/8365905-can-i-change-the-perspective-or-length-of-a-written-story"
          target="_blank"
          style={{ color: 'inherit' }}
        >
          <u>Read more about Remento’s writing styles here.</u>
        </a>
      </>
    ),
    actionLabel: 'Change transcription mode',
    requiredRoles: {
      [EntityType.PROJECT]: [AclGroupRole.OWNER, AclGroupRole.ADMIN],
    },
  },
  {
    id: UserOnboardingActionType.PHONE_COLLECTED,
    dialogType: 'custom',
    render: (props) => <PhoneCollectionOnboardingDialogContainer {...props} />,
  },
  {
    id: UserOnboardingActionType.SUBSCRIPTION_RENEW_OFFER_VIEWED,
    dialogType: 'custom',
    render: (props) => <SubscriptionRenewOfferOnboardingDialogContainer {...props} />,
    shouldShow: (user) => {
      if (user.subscription == null) {
        return false;
      }
      if (user.subscription.type !== SubscriptionType.YEARLY) {
        return false;
      }
      if (user.subscription.status !== UserSubscriptionStatus.ACTIVE) {
        return false;
      }
      if (user.subscription.renewalType === SubscriptionRenewalType.CANCEL) {
        return false;
      }

      return user.subscription.endsOn - Date.now() <= PRE_RENEWAL_PHASE_DURATION;
    },
  },
  {
    id: UserOnboardingActionType.TIMEZONE_UPDATED_VIEWED,
    dialogType: 'custom',
    render: (props) => <TimezoneUpdateOnboardingDialogContainer {...props} />,
    shouldShow: (user) => {
      if (user.timezone == null) {
        return false;
      }
      const currentTimezone = getCurrentTimezoneId();
      return currentTimezone !== user.timezone;
    },
  },
];
