import { useCallback } from 'react';
import { EntityType } from '@remento/types/entity';
import { UserPurchase, UserPurchaseStatus } from '@remento/types/user';
import { UserNotificationSettings } from '@remento/types/user-notification-settings';

import { useAbortableEffect } from '@/hooks';
import { useEntity, useEntityByForeignId } from '@/hooks/useQuery.ts';
import { useServices } from '@/Services';

export function usePersonUserQuery(personId: string | null | undefined) {
  const { userService, userCacheService } = useServices();

  return useEntityByForeignId(
    EntityType.USER,
    personId,
    async (id) => {
      const user = await userCacheService.getUserByPersonId(id);
      return user?.id ?? null;
    },
    // The personId will never be null here
    (_, scope) => userService.getUserByPersonId(personId ?? '', scope),
  );
}

export function useNotificationSettingUserQuery(userRefIds: string[] | null) {
  const { userService, userCacheService } = useServices();

  return useEntityByForeignId(
    EntityType.USER_NOTIFICATION_SETTINGS,
    userRefIds?.[0] ?? null,
    async (id) => {
      const userNotification = await userCacheService.getNotificationSettingsByUserId(id);
      return userNotification?.id ?? null;
    },
    //  The userRefIds will never be null here
    (_, scope) => userService.getNotificationSettingsByUserId(userRefIds?.[0] ?? '', scope),
    (_, userNotification: UserNotificationSettings) => userRefIds?.includes(userNotification.userId) ?? false,
  );
}

export function useUserPurchaseQuery(userPurchaseId: string | null | undefined) {
  const { userService } = useServices();

  return useEntity(EntityType.USER_PURCHASE, userPurchaseId, (id, scope) => userService.getUserPurchase(id, scope));
}

export function usePollUserPurchase(
  userPurchaseId: string | null | undefined,
  stopCallback: (purchase: UserPurchase) => boolean,
  interval?: number,
): void {
  const { userService, entityCacheManagerService } = useServices();

  useAbortableEffect(
    (signal) => {
      if (userPurchaseId == null) {
        return;
      }

      entityCacheManagerService.observeEntity({
        entityType: EntityType.USER_PURCHASE,
        entityId: userPurchaseId,
        fetchCallback: ({ signal }) => userService.getUserPurchase(userPurchaseId, { signal }),
        stopCallback,
        signal,
        interval,
      });
    },
    [entityCacheManagerService, interval, stopCallback, userPurchaseId, userService],
  );
}

export function usePollUserPurchaseUntilCompleted(userPurchaseId: string | null | undefined, interval?: number) {
  return usePollUserPurchase(
    userPurchaseId,
    useCallback((p) => p.status != UserPurchaseStatus.PENDING, []),
    interval,
  );
}
