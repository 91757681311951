import { Fragment } from 'react';

import { RMLink } from '@/components/RMLink/RMLink';
import { RMSettings } from '@/components/RMSettings';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { TextChunk } from '../../hooks/usePromptFrequencyText';

export interface PromptSettingsPreviewProps {
  storytellerFirstName: string | null;
  isCurrentUserTheStoryteller: boolean;
  promptFrequencyText: TextChunk[];
  isPaused: boolean;
  onEdit: () => void;
}

export function PromptSettingsPreview({
  storytellerFirstName,
  isCurrentUserTheStoryteller,
  promptFrequencyText,
  isPaused,
  onEdit,
}: PromptSettingsPreviewProps) {
  const mobile = useIsMobileViewport();

  return (
    <RMSettings.Section>
      <RMSettings.SectionTitle title="Prompt settings" columns={mobile ? 12 : 4} />
      <RMSettings.SectionContent columns={mobile ? 12 : 8}>
        <RMText type="sans" size="s" color="on-surface-secondary">
          {isPaused ? (
            <>
              {isCurrentUserTheStoryteller ? 'You' : storytellerFirstName} {isCurrentUserTheStoryteller ? 'are' : 'is'}{' '}
              not currently receiving prompts.
            </>
          ) : (
            <>
              {promptFrequencyText.map((text) =>
                text.bold ? <b key={text.id}>{text.value}</b> : <Fragment key={text.id}>{text.value}</Fragment>,
              )}
            </>
          )}
        </RMText>
        <RMSpacer spacing="md" direction="column" />
        <RMLink onClick={onEdit}>Edit prompt settings</RMLink>
      </RMSettings.SectionContent>
    </RMSettings.Section>
  );
}
