import { Fragment, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import WordmarkSrc from '@/assets/wordmark.svg';
import WordmarkPrimarySrc from '@/assets/wordmark-primary.svg';
import { RMBackButton } from '@/components/RMBackButton';
import { RMDateInput } from '@/components/RMDateInput/RMDateInput';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';
import { RMWeekdaySelector } from '@/components/RMWeekdaySelector/RMWeekdaySelector';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { InputController } from '@/modules/form/container';
import { submitForm, useIsFormValid } from '@/modules/form/form';
import { createPromptSettingsForm } from '@/modules/project-settings/forms/prompt-settings-form';
import { usePromptFrequencyTextByForm } from '@/modules/project-settings/hooks/usePromptFrequencyText';
import { getSetupPerspectivePath } from '@/modules/routing';
import { useServices } from '@/Services';
import { usePersonQuery } from '@/services/api/person';
import { useIsCurrentUserTheStoryteller, useProjectQuery } from '@/services/api/project';
import { createProjectCronString, parseProjectCronString } from '@/services/api/project/project.utils';
import { captureException } from '@/utils/captureException';
import { Weekday } from '@/utils/tz';

import {
  Body,
  Button,
  Header,
  HeaderLogo,
  HiddenBackButton,
  LeftPanel,
  MobileFooter,
  MobileHeader,
  RightPanel,
  Separator,
  StyledPage,
} from './SetupPromptsFrequencyPage.styles';

export function SetupPromptsFrequencyPage() {
  const isMobile = useIsMobileViewport();
  const { entityCacheManagerService, projectService } = useServices();

  const navigate = useNavigate();
  const projectId = useParams().projectId ?? '';

  const projectQuery = useProjectQuery(projectId);
  const storytellerPersonQuery = usePersonQuery(projectQuery.data?.subjectPersonIds[0]);
  const storytellerFirstName = storytellerPersonQuery.data?.name?.first ?? null;
  const isCurrentUserTheStoryteller = useIsCurrentUserTheStoryteller(projectId);

  const form = useMemo(() => {
    const cronString = projectQuery.data?.notifications.interval;
    const parsedCronString = cronString != null ? parseProjectCronString(cronString) : null;
    return createPromptSettingsForm({
      isReceivingPrompts: true,
      time: parsedCronString?.localTime,
      weekdays: parsedCronString?.weekdays,
    });
  }, [projectQuery.data?.notifications.interval]);
  const isFormValid = useIsFormValid(form);
  const promptFrequencyText = usePromptFrequencyTextByForm(projectId, form);

  const handleNext = useCallback(async () => {
    if (projectQuery.data == null) {
      return;
    }

    await submitForm(form, async (data) => {
      try {
        const cronString = createProjectCronString(data.time, data.weekdays);
        await entityCacheManagerService.mutate(
          projectService.createSetProjectNotificationIntervalMutation(projectQuery.data, cronString),
        );
        navigate(getSetupPerspectivePath(projectId));
      } catch (error) {
        toast('An unexpected error has occurred.', 'root-toast', 'error');
        captureException(error, true);
      }
    });
  }, [entityCacheManagerService, form, navigate, projectId, projectQuery.data, projectService]);

  return (
    <StyledPage>
      {isMobile ? (
        <MobileHeader>
          <HeaderLogo src={WordmarkSrc} alt="Remento wordmark" />
        </MobileHeader>
      ) : (
        <Header>
          <HeaderLogo src={WordmarkPrimarySrc} alt="Remento wordmark" />
        </Header>
      )}

      <Body>
        <LeftPanel>
          {isMobile == false && (
            // This is used to avoid shifting the layout when entering this step.
            <HiddenBackButton>
              <RMBackButton />
              <RMSpacer direction="column" spacing="2xl" />
            </HiddenBackButton>
          )}

          <RMText size={isMobile ? 'l' : 'xl'} type="serif" color="on-surface-primary">
            {isCurrentUserTheStoryteller
              ? 'How often do you want to receive prompts?'
              : `How often should ${storytellerFirstName} receive prompts?`}
          </RMText>
          <RMSpacer direction="column" spacing={isMobile ? 'sm' : 'xl'} />
          <RMText size="s" type="sans" color="on-surface-primary" lineHeight="s">
            {isCurrentUserTheStoryteller
              ? 'Your purchase includes one year of telling stories, and you can customize when Remento sends you new prompts. You can adjust these settings anytime.'
              : `Your purchase includes one year of telling stories, and you can customize when Remento sends new prompts to ${storytellerFirstName}. You can adjust these settings anytime.`}
          </RMText>

          {isMobile == false && (
            <>
              <RMSpacer direction="column" spacing="2xl" />
              <Button background="primary" autoLoading disabled={!isFormValid} onClick={handleNext}>
                Next
              </Button>
            </>
          )}
        </LeftPanel>
        <RightPanel>
          <RMSpacer direction="column" spacing="2xl" />
          <RMText type="sans" size={isMobile ? 's' : 'm'} color="on-surface-primary" bold>
            Select Day & Frequency
          </RMText>
          <RMSpacer direction="column" spacing="sm" />
          <RMText type="sans" size={isMobile ? 's' : 'm'} color="on-surface-primary">
            To send prompts more than once a week, select each day you would like a prompt sent.
          </RMText>
          <RMSpacer direction="column" spacing="md" />
          <InputController form={form} path="weekdays">
            {(props) => (
              <RMWeekdaySelector
                value={props.value ?? null}
                onChange={(newValue) => {
                  props.onChange(newValue);
                  props.onBlur();
                }}
              />
            )}
          </InputController>
          <RMSpacer direction="column" spacing="3xl" />
          <RMText type="sans" size={isMobile ? 's' : 'm'} color="on-surface-primary" bold>
            Select Delivery Time
          </RMText>
          <RMSpacer direction="column" spacing="sm" />
          <InputController form={form} path="time" immediate>
            {(props) => <RMDateInput id="prompt-time" type="time" {...props} />}
          </InputController>
          {isMobile == false && (
            <>
              <RMSpacer direction="column" spacing="3xl" />
              <Separator />
            </>
          )}
          <RMSpacer direction="column" spacing="xl" />
          <RMText type="sans" size={isMobile ? 's' : 'm'} color="on-surface-primary" italic>
            <>
              {promptFrequencyText.map((text) =>
                text.bold ? <b key={text.id}>{text.value}</b> : <Fragment key={text.id}>{text.value}</Fragment>,
              )}
            </>
          </RMText>
        </RightPanel>
      </Body>

      {isMobile && (
        <MobileFooter>
          <Button background="primary" autoLoading fullWidth disabled={!isFormValid} onClick={handleNext}>
            Next
          </Button>
        </MobileFooter>
      )}
    </StyledPage>
  );
}
