import { styled } from '@linaria/react';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
`;

export const Toggle = styled.div<{ disabled: boolean }>`
  display: flex;

  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 46px;
    height: 28px;
    background: var(--disabled);
    display: block;
    border-radius: var(--radius-full);
    position: relative;
    opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  }

  label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 1.5rem;
    height: 1.5rem;
    background-color: var(--surface-bright);
    border-radius: var(--radius-round);
    transition: 100ms ease-out;
  }

  input:checked + label {
    background-color: var(--primary);
  }

  input:checked + label:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 34px;
  }
`;

export const Label = styled.label``;
