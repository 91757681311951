import { JSX } from 'react';

import { Form } from '@/modules/form/@types';
import { LeafPaths, Paths, PathValueType } from '@/modules/form/@types-utils';
import {
  ControlledInputProps,
  InputElements,
  InputProps,
  useControlledInputProps,
  useInputProps,
  useInputValidation,
  useInputValue,
} from '@/modules/form/input';

export interface InputControllerChildProps<T> extends ControlledInputProps<T> {
  error?: string;
  value: T | null;
}
export interface InputControllerProps<In extends object, Out extends object = In, P extends Paths<In> = Paths<In>> {
  form: Form<In, Out>;
  path: P;
  immediate?: boolean;
  children: (props: InputControllerChildProps<PathValueType<In, P>>) => JSX.Element;
}
/**
 * This hooks provides the onChange and onBlur callbacks to observe the value of an input element. It is not responsible
 * for setting the value of the component and leaves that up to the render function (passed as the children prop)
 *
 * This hook is useful for components that may not use a standard HTML input element.
 * @param form
 * @param path
 */
export function InputController<In extends object, Out extends object = In, P extends Paths<In> = Paths<In>>({
  form,
  path,
  immediate = false,
  children,
}: InputControllerProps<In, Out, P>) {
  const inputProps = useControlledInputProps(form, path);
  const inputValue = useInputValue(form, path, immediate);
  const { displayError, errors } = useInputValidation(form, path);
  return children({ ...inputProps, value: inputValue ?? null, error: displayError ? errors?.[0]?.message : undefined });
}

export interface InputContainerChildProps<E extends InputElements> extends InputProps<E> {
  error?: string;
}
export interface InputContainerProps<
  In extends object,
  Out extends object = In,
  E extends InputElements = InputElements,
  P extends LeafPaths<In, string> = LeafPaths<In, string>,
> {
  form: Form<In, Out>;
  path: P;
  children: (props: InputContainerChildProps<E>) => JSX.Element;
}

/**
 * This component provides the onChange, onRef and onBlur callbacks to control an input element. It manages the value
 * of the input element directly via the ref. It can only be used with properties of type string.
 * @param form
 * @param path
 * @param children
 * @constructor
 */
export function InputContainer<
  In extends object,
  Out extends object = In,
  E extends InputElements = InputElements,
  P extends LeafPaths<In, string> = LeafPaths<In, string>,
>({ form, path, children }: InputContainerProps<In, Out, E, P>) {
  const inputProps = useInputProps<In, Out, E, P>(form, path);
  const { displayError, errors } = useInputValidation(form, path);
  return children({ ...inputProps, error: displayError ? errors?.[0]?.message : undefined });
}
