import { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import lottie, { AnimationItem } from 'lottie-web';

import ThinkingLottieAnimation from '@/@assets/lottie.thinking.json';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';
import { useUser } from '@/services/api/auth/auth.service.hook';

import { Animation, Content, Footer } from './ReSignInModal.styles';

type ReSignInModalProps = {
  open: boolean;
  onReSignIn: () => void;
};

export function ReSignInModal({ open, onReSignIn }: ReSignInModalProps) {
  const user = useUser();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const emailFromLink = searchParams.get('email');

  const userEmail = useMemo(() => {
    if (!user) {
      return null;
    }
    return user.communicationChannels.email;
  }, [user]);
  const sameUser = userEmail === emailFromLink;

  // Trouble modal
  const [troubleModalOpen, setTroubleModalOpen] = useState(false);

  const openTroubleModal = useCallback(() => {
    setTroubleModalOpen(true);
  }, []);

  const closeTroubleModal = useCallback(() => {
    setTroubleModalOpen(false);
  }, []);

  const handleCopyUrl = useCallback(() => {
    navigator.clipboard.writeText(window.location.href);
    toast('Link copied to clipboard', 'root-toast', 'success');
  }, []);

  // Actions
  const goHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  // Lottie Animation
  const animationRef = useRef<AnimationItem | null>();
  const lottieRef = useCallback((node: HTMLDivElement | null) => {
    if (node === null) {
      animationRef.current?.destroy();
      animationRef.current = null;
      return;
    }

    const animation = lottie.loadAnimation({
      container: node,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: ThinkingLottieAnimation,
    });
    animationRef.current = animation;
  }, []);

  return (
    <RMDialog.Root open={open}>
      <RMDialog.Root open={troubleModalOpen} onClose={closeTroubleModal}>
        <RMDialog.Content>
          <RMDialog.Header rightAdornment={<RMCloseButton onClick={closeTroubleModal} />} />

          <RMDialog.Body>
            <RMText type="serif" size="l" style={{ textAlign: 'center' }}>
              Trouble logging in?
            </RMText>
            <RMSpacer spacing="xl" direction="column" />
            <RMText type="sans" size="s" style={{ textAlign: 'center' }}>
              Copy the link below and paste it in a web browser.
            </RMText>
          </RMDialog.Body>

          <RMDialog.Footer>
            <RMButton fullWidth onClick={handleCopyUrl} rightIcon={faCopy}>
              Copy URL
            </RMButton>
          </RMDialog.Footer>
        </RMDialog.Content>
      </RMDialog.Root>

      <RMDialog.Content>
        <RMDialog.Body>
          <Content>
            <Animation ref={lottieRef} />
            <RMText type="sans" size="s" color="on-surface-primary">
              {'It looks like you’re trying to login with '}
              <b>{emailFromLink}</b>
              {sameUser ? (
                ', but it appears you’re already signed in.'
              ) : (
                <>
                  {', but it appears you’re already signed in as '}
                  <b>{userEmail}</b>.
                </>
              )}
            </RMText>
            {!sameUser && (
              <RMText type="sans" size="s">
                Which email address would you like to proceed with?
              </RMText>
            )}
          </Content>
        </RMDialog.Body>

        <RMDialog.Footer>
          <Footer>
            {sameUser ? (
              <RMButton background="neutral" onClick={goHome} fullWidth>
                Continue
              </RMButton>
            ) : (
              <>
                <RMButton background="neutral" onClick={onReSignIn} fullWidth>
                  {emailFromLink}
                </RMButton>
                <RMButton background="neutral" onClick={goHome} fullWidth>
                  {userEmail}
                </RMButton>
              </>
            )}
            <RMButton background="outlined" onClick={openTroubleModal} fullWidth>
              Having trouble logging in?
            </RMButton>
          </Footer>
        </RMDialog.Footer>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
