import { logger } from '@/logger';

import { LocalStoreRepository } from '../local-store';

import { RedirectAction, RedirectService } from './redirect.types';

export class RedirectServiceImpl implements RedirectService {
  constructor(private localStore: LocalStoreRepository, private pathBasename: string) {}

  async registerRedirect(action: RedirectAction, path: string): Promise<void> {
    logger.debug('REGISTER_REDIRECT', { action, path });

    // We should not store the path basename in the redirects.
    // React router will add it when calling navigate().
    let cleansedPath = path;
    if (this.pathBasename.length > 0 && cleansedPath.startsWith(this.pathBasename)) {
      cleansedPath = cleansedPath.substring(this.pathBasename.length);
    }
    this.localStore.setItem(action, cleansedPath);
  }

  async consumeRedirect(action: RedirectAction): Promise<string | null> {
    const redirect = this.localStore.getItem(action);
    this.localStore.removeItem(action);
    logger.debug('CONSUME_REDIRECT', { action, redirect });
    return redirect;
  }

  async clearAllRedirects(): Promise<void> {
    logger.debug('CLEAR_ALL_REDIRECTS');
    this.localStore.clear();
  }
}
