import { Project } from '@remento/types/project';
import cronParser from 'cron-parser';
import dayjs from 'dayjs';

import { getCurrentTimezoneId, Weekday } from '@/utils/tz';

export function getProjectCron(project: Project) {
  const giftDate = project.startsOn;
  const cron = cronParser.parseExpression(project.notifications.interval, {
    currentDate: Math.max(Date.now(), giftDate),
    utc: true,
  });

  // If the series start date is the same as the first prompt date,
  // the first prompt should be sent at the next date.
  const nextDate = dayjs(cron.next().toDate());
  if (nextDate.isSame(giftDate, 'day') === false) {
    cron.reset();
  }

  return cron;
}

export function parseProjectCronString(cronString: string): { localTime: string; weekdays: Set<Weekday> } {
  const cron = cronParser.parseExpression(cronString, {
    utc: true,
  });

  const weekdays = cron.fields.dayOfWeek.map((dayOfWeek) => {
    switch (dayOfWeek) {
      case 0:
      case 7:
        return Weekday.SUNDAY;
      case 1:
        return Weekday.MONDAY;
      case 2:
        return Weekday.TUESDAY;
      case 3:
        return Weekday.WEDNESDAY;
      case 4:
        return Weekday.THURSDAY;
      case 5:
        return Weekday.FRIDAY;
      case 6:
        return Weekday.SATURDAY;
    }
  });

  const utcTime = dayjs.utc(`${cron.fields.hour}:${cron.fields.minute}`, 'H:m');
  const localTime = utcTime.tz(getCurrentTimezoneId()).format('HH:mm');

  return { localTime, weekdays: new Set(weekdays) };
}

export function createProjectCronString(localTime: string, weekdays: Set<Weekday>): string {
  if (weekdays.size == 0) {
    throw new Error('Weekdays cannot be empty');
  }

  const utcTime = dayjs.tz(localTime, 'HH:mm', getCurrentTimezoneId()).utc().format('H:m');
  const [hours, minutes] = utcTime.split(':');
  let dayOfWeek = '*';
  if (weekdays.size !== 7) {
    dayOfWeek = Array.from(weekdays)
      .map((weekday) => {
        switch (weekday) {
          case Weekday.SUNDAY:
            return 'SUN';
          case Weekday.MONDAY:
            return 'MON';
          case Weekday.TUESDAY:
            return 'TUE';
          case Weekday.WEDNESDAY:
            return 'WED';
          case Weekday.THURSDAY:
            return 'THU';
          case Weekday.FRIDAY:
            return 'FRI';
          case Weekday.SATURDAY:
            return 'SAT';
        }
      })
      .join(',');
  }

  return [
    // seconds
    '0',
    // minutes
    minutes,
    // hours
    hours,
    // dayOfMonth
    '?',
    // month
    '*',
    // dayOfWeek
    dayOfWeek,
  ].join(' ');
}
