import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import QRCodeStyling from 'qr-code-styling';

import LogoSrc from '@/@assets/logomark-2.svg';
import WordmarkSrc from '@/assets/wordmark.svg';
import DarkWordmarkSrc from '@/assets/wordmark-dark.svg';

import BabybookBookSrc from './babybook-book.png';
import BiographyBookSrc from './biography-book.png';
import {
  BabybookImage,
  BabybookPageConfig,
  BiographyImage,
  BiographyPageConfig,
  CommonPageConfig,
  CouponText,
  CouponWrapper,
  DescriptionText,
  FooterText,
  FooterWrapper,
  GiftCardContent,
  GiftCardRoot,
  ImageWrapper,
  QrCodeWrapper,
  TitleText,
  VisitText,
  WordmarkImage,
} from './GiftCard.styles';

interface RemoteData {
  projectType: 'BIOGRAPHY' | 'BABYBOOK';
  extraBooks: number;
  ebook: boolean;
  promoCode: string;
  checkoutUrl: string;
}

const TEST_DATA: RemoteData = {
  projectType: 'BIOGRAPHY',
  extraBooks: 2,
  ebook: true,
  checkoutUrl: 'https://dev.remento.co/app/book/checkout',
  promoCode: '12COUPON34',
};

export function GiftCard() {
  const [data, setData] = useState<RemoteData | null>(null);
  const [qrCode, setQrCode] = useState<string>('');

  useEffect(() => {
    axios
      .get<RemoteData>('/__data__.json')
      .then(
        (response) => response.data,
        () => TEST_DATA,
      )
      .then(async (remoteData) => {
        const qrCode = new QRCodeStyling({
          width: 300,
          height: 300,
          type: 'svg',
          data: remoteData.checkoutUrl,
          image: LogoSrc,
          dotsOptions: {
            type: 'classy-rounded',
            color: '#233D33',
          },
          cornersSquareOptions: {
            type: 'extra-rounded',
          },
          backgroundOptions: {
            color: 'white',
          },
          imageOptions: {
            imageSize: 0.5,
            margin: 4,
          },
          qrOptions: {
            errorCorrectionLevel: 'M',
          },
        });
        const svgData = await qrCode.getRawData('svg');
        if (svgData == null) throw new Error('ERROR_GENERATING_QR_CODE');

        setQrCode(URL.createObjectURL(svgData));
        setData(remoteData);
      });
  }, []);

  const description = useMemo(() => {
    if (data == null) {
      return '';
    }

    if (data.extraBooks > 0) {
      const extraBooksStrings = ['zero', 'one', 'two', 'three', 'four', 'five'];
      const extraBooksString = extraBooksStrings[data.extraBooks];

      if (data.ebook) {
        return `Valid for one year of storytelling and one hardcover book, plus ${extraBooksString} extra hardcover book${
          data.extraBooks > 1 ? 's' : ''
        } and an e-book.`;
      }
      return `Valid for one year of storytelling and one hardcover book, plus ${extraBooksString} extra hardcover book${
        data.extraBooks > 1 ? 's' : ''
      }.`;
    }

    if (data.ebook) {
      return 'Valid for one year of storytelling and one hardcover book, plus an e-book';
    }
    return 'Valid for one year of storytelling and one hardcover book.';
  }, [data]);

  if (data === null) {
    return null;
  }

  return (
    <>
      <Helmet>
        <style>{CommonPageConfig}</style>
        {data.projectType === 'BIOGRAPHY' && <style>{BiographyPageConfig}</style>}
        {data.projectType === 'BABYBOOK' && <style>{BabybookPageConfig}</style>}
      </Helmet>
      <GiftCardRoot>
        <ImageWrapper>
          {data.projectType === 'BIOGRAPHY' && <BiographyImage src={BiographyBookSrc} />}
          {data.projectType === 'BABYBOOK' && <BabybookImage src={BabybookBookSrc} />}
        </ImageWrapper>
        <GiftCardContent>
          <WordmarkImage src={data.projectType === 'BIOGRAPHY' ? WordmarkSrc : DarkWordmarkSrc} />
          <TitleText data-theme={data.projectType === 'BIOGRAPHY' ? 'dark' : 'light'}>Gift Certificate</TitleText>
          <DescriptionText data-theme={data.projectType === 'BIOGRAPHY' ? 'dark' : 'light'}>
            {description}
          </DescriptionText>
          <CouponWrapper>
            <CouponText>{data.promoCode}</CouponText>
          </CouponWrapper>
          <VisitText data-theme={data.projectType === 'BIOGRAPHY' ? 'dark' : 'light'}>
            Visit www.remento.co and use
            <br />
            this code at checkout to redeem.
          </VisitText>
          <FooterWrapper>
            <QrCodeWrapper href={data.checkoutUrl} target="_blank">
              <img src={qrCode} />
            </QrCodeWrapper>
            <FooterText data-theme={data.projectType === 'BIOGRAPHY' ? 'dark' : 'light'}>
              This gift certificate may be applied toward the purchase of Remento , which includes a year digital
              subscription plus one color-printed, hardcover book up to 200 pages. It is not exchangeable for cash. If
              an order exceeds the amount of the gift certificate, the balance of the order must be paid with a credit
              card. Gift certificates and their use are subject to Remento’s general Terms & Conditions. Remento
              reserves the right to changes these terms and conditions from time to time at its discretion
            </FooterText>
          </FooterWrapper>
        </GiftCardContent>
      </GiftCardRoot>
    </>
  );
}
