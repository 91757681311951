import { PersonName } from '@remento/types/person';

import LockIcon from '@/assets/icons/lock.svg';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMError } from '@/components/RMError';
import { RMText } from '@/components/RMText/RMText';

import { LogoutLink } from './UnauthorizedPage.styles';

export interface SignedInUser {
  name: PersonName | null;
  email: string;
}

export interface UnauthorizedPageProps {
  isRecordingPage: boolean;
  user: SignedInUser;
  onRedirect: () => void;
  onSignOut: (redirect?: boolean) => Promise<void>;
}

export function UnauthorizedPage({ isRecordingPage, user, onRedirect, onSignOut }: UnauthorizedPageProps) {
  return (
    <RMError.Root
      icon={isRecordingPage ? LockIcon : null}
      title={isRecordingPage ? 'Access denied' : 'Uh Oh! Let’s try again'}
      Action={<RMButton onClick={onRedirect}>Go to Prompts page</RMButton>}
      Message={
        <>
          <RMError.Text>
            {isRecordingPage
              ? 'You don’t have access to this recording link. Either tap the link sent to you via email or press “Record” in Remento.'
              : 'Head over to your prompts page to continue exploring Remento.'}
          </RMError.Text>
          <RMText align="center" type="sans" size="s" color="on-surface-primary">
            Not{' '}
            <RMText type="sans" bold size="s" color="on-surface-primary">
              {user?.name?.first} ({user.email})
            </RMText>
            ? <br />
            <LogoutLink onClick={() => onSignOut(false)}>Log out</LogoutLink>.
          </RMText>
        </>
      }
    />
  );
}
