import { useMemo } from 'react';

import { RMInput } from '../RMInput';

export interface RMDateInputProps {
  id: string;
  type: 'date' | 'datetime' | 'time';
  label?: string;
  placeholder?: string;
  defaultTime?: string;
  value?: string | null;
  error?: string | null;
  min?: string;
  disabled?: boolean;
  onChange?: (value: string | null) => void;
  onBlur?: () => void;
}

export function RMDateInput({
  type,
  defaultTime = '00:00:00',
  value,
  disabled,
  onChange,
  onBlur,
  ...props
}: RMDateInputProps) {
  const parsedValue = useMemo(() => {
    if (value == null) {
      return '';
    }
    if (type === 'time') {
      // The value will always be in the hh:mm format
      return value;
    }
    if (type === 'date' && value.includes('T')) {
      return value.split('T')[0];
    }
    if (type === 'datetime' && !value.includes('T')) {
      return `${value}T${defaultTime}`;
    }
    return value;
  }, [value, type, defaultTime]);

  return (
    <RMInput
      {...props}
      value={parsedValue}
      type={type === 'datetime' ? 'datetime-local' : type}
      max={type !== 'time' ? '2999-12-31' : undefined}
      disabled={disabled}
      onChange={(e) => {
        const newValue = e.target.value;

        if (type === 'time') {
          onChange?.(newValue);
          return;
        }

        if (newValue.includes('T')) {
          onChange?.(newValue);
          return;
        }
        onChange?.(`${newValue}T${defaultTime}`);
      }}
      onBlur={onBlur}
    />
  );
}
